@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Muli:600,700|Open+Sans:400,700&display=swap");
/**
 * Foundation for Sites
 * Version 6.7.3
 * https://get.foundation
 * Licensed under MIT Open Source
 */
@import url(~swiper/swiper-bundle.css);
.grid-container {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  @media print, screen and (min-width: 40em) {
    .grid-container {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .grid-container.fluid {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto; }
    @media print, screen and (min-width: 40em) {
      .grid-container.fluid {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem; } }
  .grid-container.full {
    padding-right: 0;
    padding-left: 0;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto; }

.grid-x {
  display: flex;
  flex-flow: row wrap; }

.cell {
  flex: 0 0 auto;
  min-height: 0px;
  min-width: 0px;
  width: 100%; }
  .cell.auto {
    flex: 1 1 0px; }
  .cell.shrink {
    flex: 0 0 auto; }

.grid-x > .auto {
  width: auto; }

.grid-x > .shrink {
  width: auto; }

.grid-x > .small-shrink, .grid-x > .small-full, .grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
  flex-basis: auto; }

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-shrink, .grid-x > .medium-full, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    flex-basis: auto; } }

@media print, screen and (min-width: 64em) {
  .grid-x > .large-shrink, .grid-x > .large-full, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    flex-basis: auto; } }

.grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
  flex: 0 0 auto; }

.grid-x > .small-1 {
  width: 8.33333%; }

.grid-x > .small-2 {
  width: 16.66667%; }

.grid-x > .small-3 {
  width: 25%; }

.grid-x > .small-4 {
  width: 33.33333%; }

.grid-x > .small-5 {
  width: 41.66667%; }

.grid-x > .small-6 {
  width: 50%; }

.grid-x > .small-7 {
  width: 58.33333%; }

.grid-x > .small-8 {
  width: 66.66667%; }

.grid-x > .small-9 {
  width: 75%; }

.grid-x > .small-10 {
  width: 83.33333%; }

.grid-x > .small-11 {
  width: 91.66667%; }

.grid-x > .small-12 {
  width: 100%; }

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .medium-shrink, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    flex: 0 0 auto; }
  .grid-x > .medium-shrink {
    width: auto; }
  .grid-x > .medium-1 {
    width: 8.33333%; }
  .grid-x > .medium-2 {
    width: 16.66667%; }
  .grid-x > .medium-3 {
    width: 25%; }
  .grid-x > .medium-4 {
    width: 33.33333%; }
  .grid-x > .medium-5 {
    width: 41.66667%; }
  .grid-x > .medium-6 {
    width: 50%; }
  .grid-x > .medium-7 {
    width: 58.33333%; }
  .grid-x > .medium-8 {
    width: 66.66667%; }
  .grid-x > .medium-9 {
    width: 75%; }
  .grid-x > .medium-10 {
    width: 83.33333%; }
  .grid-x > .medium-11 {
    width: 91.66667%; }
  .grid-x > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .grid-x > .large-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .large-shrink, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    flex: 0 0 auto; }
  .grid-x > .large-shrink {
    width: auto; }
  .grid-x > .large-1 {
    width: 8.33333%; }
  .grid-x > .large-2 {
    width: 16.66667%; }
  .grid-x > .large-3 {
    width: 25%; }
  .grid-x > .large-4 {
    width: 33.33333%; }
  .grid-x > .large-5 {
    width: 41.66667%; }
  .grid-x > .large-6 {
    width: 50%; }
  .grid-x > .large-7 {
    width: 58.33333%; }
  .grid-x > .large-8 {
    width: 66.66667%; }
  .grid-x > .large-9 {
    width: 75%; }
  .grid-x > .large-10 {
    width: 83.33333%; }
  .grid-x > .large-11 {
    width: 91.66667%; }
  .grid-x > .large-12 {
    width: 100%; } }

.grid-margin-x:not(.grid-x) > .cell {
  width: auto; }

.grid-margin-y:not(.grid-y) > .cell {
  height: auto; }

.grid-margin-x {
  margin-left: -0.625rem;
  margin-right: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x {
      margin-left: -0.9375rem;
      margin-right: -0.9375rem; } }
  .grid-margin-x > .cell {
    width: calc(100% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x > .cell {
      width: calc(100% - 1.875rem);
      margin-left: 0.9375rem;
      margin-right: 0.9375rem; } }
  .grid-margin-x > .auto {
    width: auto; }
  .grid-margin-x > .shrink {
    width: auto; }
  .grid-margin-x > .small-1 {
    width: calc(8.33333% - 1.25rem); }
  .grid-margin-x > .small-2 {
    width: calc(16.66667% - 1.25rem); }
  .grid-margin-x > .small-3 {
    width: calc(25% - 1.25rem); }
  .grid-margin-x > .small-4 {
    width: calc(33.33333% - 1.25rem); }
  .grid-margin-x > .small-5 {
    width: calc(41.66667% - 1.25rem); }
  .grid-margin-x > .small-6 {
    width: calc(50% - 1.25rem); }
  .grid-margin-x > .small-7 {
    width: calc(58.33333% - 1.25rem); }
  .grid-margin-x > .small-8 {
    width: calc(66.66667% - 1.25rem); }
  .grid-margin-x > .small-9 {
    width: calc(75% - 1.25rem); }
  .grid-margin-x > .small-10 {
    width: calc(83.33333% - 1.25rem); }
  .grid-margin-x > .small-11 {
    width: calc(91.66667% - 1.25rem); }
  .grid-margin-x > .small-12 {
    width: calc(100% - 1.25rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x > .auto {
      width: auto; }
    .grid-margin-x > .shrink {
      width: auto; }
    .grid-margin-x > .small-1 {
      width: calc(8.33333% - 1.875rem); }
    .grid-margin-x > .small-2 {
      width: calc(16.66667% - 1.875rem); }
    .grid-margin-x > .small-3 {
      width: calc(25% - 1.875rem); }
    .grid-margin-x > .small-4 {
      width: calc(33.33333% - 1.875rem); }
    .grid-margin-x > .small-5 {
      width: calc(41.66667% - 1.875rem); }
    .grid-margin-x > .small-6 {
      width: calc(50% - 1.875rem); }
    .grid-margin-x > .small-7 {
      width: calc(58.33333% - 1.875rem); }
    .grid-margin-x > .small-8 {
      width: calc(66.66667% - 1.875rem); }
    .grid-margin-x > .small-9 {
      width: calc(75% - 1.875rem); }
    .grid-margin-x > .small-10 {
      width: calc(83.33333% - 1.875rem); }
    .grid-margin-x > .small-11 {
      width: calc(91.66667% - 1.875rem); }
    .grid-margin-x > .small-12 {
      width: calc(100% - 1.875rem); }
    .grid-margin-x > .medium-auto {
      width: auto; }
    .grid-margin-x > .medium-shrink {
      width: auto; }
    .grid-margin-x > .medium-1 {
      width: calc(8.33333% - 1.875rem); }
    .grid-margin-x > .medium-2 {
      width: calc(16.66667% - 1.875rem); }
    .grid-margin-x > .medium-3 {
      width: calc(25% - 1.875rem); }
    .grid-margin-x > .medium-4 {
      width: calc(33.33333% - 1.875rem); }
    .grid-margin-x > .medium-5 {
      width: calc(41.66667% - 1.875rem); }
    .grid-margin-x > .medium-6 {
      width: calc(50% - 1.875rem); }
    .grid-margin-x > .medium-7 {
      width: calc(58.33333% - 1.875rem); }
    .grid-margin-x > .medium-8 {
      width: calc(66.66667% - 1.875rem); }
    .grid-margin-x > .medium-9 {
      width: calc(75% - 1.875rem); }
    .grid-margin-x > .medium-10 {
      width: calc(83.33333% - 1.875rem); }
    .grid-margin-x > .medium-11 {
      width: calc(91.66667% - 1.875rem); }
    .grid-margin-x > .medium-12 {
      width: calc(100% - 1.875rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-x > .large-auto {
      width: auto; }
    .grid-margin-x > .large-shrink {
      width: auto; }
    .grid-margin-x > .large-1 {
      width: calc(8.33333% - 1.875rem); }
    .grid-margin-x > .large-2 {
      width: calc(16.66667% - 1.875rem); }
    .grid-margin-x > .large-3 {
      width: calc(25% - 1.875rem); }
    .grid-margin-x > .large-4 {
      width: calc(33.33333% - 1.875rem); }
    .grid-margin-x > .large-5 {
      width: calc(41.66667% - 1.875rem); }
    .grid-margin-x > .large-6 {
      width: calc(50% - 1.875rem); }
    .grid-margin-x > .large-7 {
      width: calc(58.33333% - 1.875rem); }
    .grid-margin-x > .large-8 {
      width: calc(66.66667% - 1.875rem); }
    .grid-margin-x > .large-9 {
      width: calc(75% - 1.875rem); }
    .grid-margin-x > .large-10 {
      width: calc(83.33333% - 1.875rem); }
    .grid-margin-x > .large-11 {
      width: calc(91.66667% - 1.875rem); }
    .grid-margin-x > .large-12 {
      width: calc(100% - 1.875rem); } }

.grid-padding-x .grid-padding-x {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-x .grid-padding-x {
      margin-right: -0.9375rem;
      margin-left: -0.9375rem; } }

.grid-container:not(.full) > .grid-padding-x {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-container:not(.full) > .grid-padding-x {
      margin-right: -0.9375rem;
      margin-left: -0.9375rem; } }

.grid-padding-x > .cell {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-x > .cell {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }

.small-up-1 > .cell {
  width: 100%; }

.small-up-2 > .cell {
  width: 50%; }

.small-up-3 > .cell {
  width: 33.33333%; }

.small-up-4 > .cell {
  width: 25%; }

.small-up-5 > .cell {
  width: 20%; }

.small-up-6 > .cell {
  width: 16.66667%; }

.small-up-7 > .cell {
  width: 14.28571%; }

.small-up-8 > .cell {
  width: 12.5%; }

@media print, screen and (min-width: 40em) {
  .medium-up-1 > .cell {
    width: 100%; }
  .medium-up-2 > .cell {
    width: 50%; }
  .medium-up-3 > .cell {
    width: 33.33333%; }
  .medium-up-4 > .cell {
    width: 25%; }
  .medium-up-5 > .cell {
    width: 20%; }
  .medium-up-6 > .cell {
    width: 16.66667%; }
  .medium-up-7 > .cell {
    width: 14.28571%; }
  .medium-up-8 > .cell {
    width: 12.5%; } }

@media print, screen and (min-width: 64em) {
  .large-up-1 > .cell {
    width: 100%; }
  .large-up-2 > .cell {
    width: 50%; }
  .large-up-3 > .cell {
    width: 33.33333%; }
  .large-up-4 > .cell {
    width: 25%; }
  .large-up-5 > .cell {
    width: 20%; }
  .large-up-6 > .cell {
    width: 16.66667%; }
  .large-up-7 > .cell {
    width: 14.28571%; }
  .large-up-8 > .cell {
    width: 12.5%; } }

.grid-margin-x.small-up-1 > .cell {
  width: calc(100% - 1.25rem); }

.grid-margin-x.small-up-2 > .cell {
  width: calc(50% - 1.25rem); }

.grid-margin-x.small-up-3 > .cell {
  width: calc(33.33333% - 1.25rem); }

.grid-margin-x.small-up-4 > .cell {
  width: calc(25% - 1.25rem); }

.grid-margin-x.small-up-5 > .cell {
  width: calc(20% - 1.25rem); }

.grid-margin-x.small-up-6 > .cell {
  width: calc(16.66667% - 1.25rem); }

.grid-margin-x.small-up-7 > .cell {
  width: calc(14.28571% - 1.25rem); }

.grid-margin-x.small-up-8 > .cell {
  width: calc(12.5% - 1.25rem); }

@media print, screen and (min-width: 40em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 1.875rem); }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 1.875rem); }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 1.875rem); }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 1.875rem); }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.28571% - 1.875rem); }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 1.875rem); }
  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 1.875rem); }
  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 1.875rem); }
  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 1.875rem); }
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 1.875rem); }
  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.28571% - 1.875rem); }
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 1.875rem); } }

@media print, screen and (min-width: 64em) {
  .grid-margin-x.large-up-1 > .cell {
    width: calc(100% - 1.875rem); }
  .grid-margin-x.large-up-2 > .cell {
    width: calc(50% - 1.875rem); }
  .grid-margin-x.large-up-3 > .cell {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x.large-up-4 > .cell {
    width: calc(25% - 1.875rem); }
  .grid-margin-x.large-up-5 > .cell {
    width: calc(20% - 1.875rem); }
  .grid-margin-x.large-up-6 > .cell {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x.large-up-7 > .cell {
    width: calc(14.28571% - 1.875rem); }
  .grid-margin-x.large-up-8 > .cell {
    width: calc(12.5% - 1.875rem); } }

.small-margin-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0; }
  .small-margin-collapse > .small-1 {
    width: 8.33333%; }
  .small-margin-collapse > .small-2 {
    width: 16.66667%; }
  .small-margin-collapse > .small-3 {
    width: 25%; }
  .small-margin-collapse > .small-4 {
    width: 33.33333%; }
  .small-margin-collapse > .small-5 {
    width: 41.66667%; }
  .small-margin-collapse > .small-6 {
    width: 50%; }
  .small-margin-collapse > .small-7 {
    width: 58.33333%; }
  .small-margin-collapse > .small-8 {
    width: 66.66667%; }
  .small-margin-collapse > .small-9 {
    width: 75%; }
  .small-margin-collapse > .small-10 {
    width: 83.33333%; }
  .small-margin-collapse > .small-11 {
    width: 91.66667%; }
  .small-margin-collapse > .small-12 {
    width: 100%; }
  @media print, screen and (min-width: 40em) {
    .small-margin-collapse > .medium-1 {
      width: 8.33333%; }
    .small-margin-collapse > .medium-2 {
      width: 16.66667%; }
    .small-margin-collapse > .medium-3 {
      width: 25%; }
    .small-margin-collapse > .medium-4 {
      width: 33.33333%; }
    .small-margin-collapse > .medium-5 {
      width: 41.66667%; }
    .small-margin-collapse > .medium-6 {
      width: 50%; }
    .small-margin-collapse > .medium-7 {
      width: 58.33333%; }
    .small-margin-collapse > .medium-8 {
      width: 66.66667%; }
    .small-margin-collapse > .medium-9 {
      width: 75%; }
    .small-margin-collapse > .medium-10 {
      width: 83.33333%; }
    .small-margin-collapse > .medium-11 {
      width: 91.66667%; }
    .small-margin-collapse > .medium-12 {
      width: 100%; } }
  @media print, screen and (min-width: 64em) {
    .small-margin-collapse > .large-1 {
      width: 8.33333%; }
    .small-margin-collapse > .large-2 {
      width: 16.66667%; }
    .small-margin-collapse > .large-3 {
      width: 25%; }
    .small-margin-collapse > .large-4 {
      width: 33.33333%; }
    .small-margin-collapse > .large-5 {
      width: 41.66667%; }
    .small-margin-collapse > .large-6 {
      width: 50%; }
    .small-margin-collapse > .large-7 {
      width: 58.33333%; }
    .small-margin-collapse > .large-8 {
      width: 66.66667%; }
    .small-margin-collapse > .large-9 {
      width: 75%; }
    .small-margin-collapse > .large-10 {
      width: 83.33333%; }
    .small-margin-collapse > .large-11 {
      width: 91.66667%; }
    .small-margin-collapse > .large-12 {
      width: 100%; } }

.small-padding-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .small-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .small-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .small-3 {
    width: 25%; }
  .medium-margin-collapse > .small-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .small-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .small-6 {
    width: 50%; }
  .medium-margin-collapse > .small-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .small-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .small-9 {
    width: 75%; }
  .medium-margin-collapse > .small-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .small-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .medium-3 {
    width: 25%; }
  .medium-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .medium-6 {
    width: 50%; }
  .medium-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .medium-9 {
    width: 75%; }
  .medium-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .medium-margin-collapse > .large-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .large-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .large-3 {
    width: 25%; }
  .medium-margin-collapse > .large-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .large-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .large-6 {
    width: 50%; }
  .medium-margin-collapse > .large-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .large-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .large-9 {
    width: 75%; }
  .medium-margin-collapse > .large-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .large-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .large-12 {
    width: 100%; } }

@media print, screen and (min-width: 40em) {
  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .small-1 {
    width: 8.33333%; }
  .large-margin-collapse > .small-2 {
    width: 16.66667%; }
  .large-margin-collapse > .small-3 {
    width: 25%; }
  .large-margin-collapse > .small-4 {
    width: 33.33333%; }
  .large-margin-collapse > .small-5 {
    width: 41.66667%; }
  .large-margin-collapse > .small-6 {
    width: 50%; }
  .large-margin-collapse > .small-7 {
    width: 58.33333%; }
  .large-margin-collapse > .small-8 {
    width: 66.66667%; }
  .large-margin-collapse > .small-9 {
    width: 75%; }
  .large-margin-collapse > .small-10 {
    width: 83.33333%; }
  .large-margin-collapse > .small-11 {
    width: 91.66667%; }
  .large-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .large-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .large-margin-collapse > .medium-3 {
    width: 25%; }
  .large-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .large-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .large-margin-collapse > .medium-6 {
    width: 50%; }
  .large-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .large-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .large-margin-collapse > .medium-9 {
    width: 75%; }
  .large-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .large-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .large-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .large-1 {
    width: 8.33333%; }
  .large-margin-collapse > .large-2 {
    width: 16.66667%; }
  .large-margin-collapse > .large-3 {
    width: 25%; }
  .large-margin-collapse > .large-4 {
    width: 33.33333%; }
  .large-margin-collapse > .large-5 {
    width: 41.66667%; }
  .large-margin-collapse > .large-6 {
    width: 50%; }
  .large-margin-collapse > .large-7 {
    width: 58.33333%; }
  .large-margin-collapse > .large-8 {
    width: 66.66667%; }
  .large-margin-collapse > .large-9 {
    width: 75%; }
  .large-margin-collapse > .large-10 {
    width: 83.33333%; }
  .large-margin-collapse > .large-11 {
    width: 91.66667%; }
  .large-margin-collapse > .large-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

.small-offset-0 {
  margin-left: 0%; }

.grid-margin-x > .small-offset-0 {
  margin-left: calc(0% + 1.25rem / 2); }

.small-offset-1 {
  margin-left: 8.33333%; }

.grid-margin-x > .small-offset-1 {
  margin-left: calc(8.33333% + 1.25rem / 2); }

.small-offset-2 {
  margin-left: 16.66667%; }

.grid-margin-x > .small-offset-2 {
  margin-left: calc(16.66667% + 1.25rem / 2); }

.small-offset-3 {
  margin-left: 25%; }

.grid-margin-x > .small-offset-3 {
  margin-left: calc(25% + 1.25rem / 2); }

.small-offset-4 {
  margin-left: 33.33333%; }

.grid-margin-x > .small-offset-4 {
  margin-left: calc(33.33333% + 1.25rem / 2); }

.small-offset-5 {
  margin-left: 41.66667%; }

.grid-margin-x > .small-offset-5 {
  margin-left: calc(41.66667% + 1.25rem / 2); }

.small-offset-6 {
  margin-left: 50%; }

.grid-margin-x > .small-offset-6 {
  margin-left: calc(50% + 1.25rem / 2); }

.small-offset-7 {
  margin-left: 58.33333%; }

.grid-margin-x > .small-offset-7 {
  margin-left: calc(58.33333% + 1.25rem / 2); }

.small-offset-8 {
  margin-left: 66.66667%; }

.grid-margin-x > .small-offset-8 {
  margin-left: calc(66.66667% + 1.25rem / 2); }

.small-offset-9 {
  margin-left: 75%; }

.grid-margin-x > .small-offset-9 {
  margin-left: calc(75% + 1.25rem / 2); }

.small-offset-10 {
  margin-left: 83.33333%; }

.grid-margin-x > .small-offset-10 {
  margin-left: calc(83.33333% + 1.25rem / 2); }

.small-offset-11 {
  margin-left: 91.66667%; }

.grid-margin-x > .small-offset-11 {
  margin-left: calc(91.66667% + 1.25rem / 2); }

@media print, screen and (min-width: 40em) {
  .medium-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .medium-offset-0 {
    margin-left: calc(0% + 1.875rem / 2); }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(8.33333% + 1.875rem / 2); }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(16.66667% + 1.875rem / 2); }
  .medium-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(25% + 1.875rem / 2); }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(33.33333% + 1.875rem / 2); }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(41.66667% + 1.875rem / 2); }
  .medium-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(50% + 1.875rem / 2); }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(58.33333% + 1.875rem / 2); }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(66.66667% + 1.875rem / 2); }
  .medium-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(75% + 1.875rem / 2); }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(83.33333% + 1.875rem / 2); }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(91.66667% + 1.875rem / 2); } }

@media print, screen and (min-width: 64em) {
  .large-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .large-offset-0 {
    margin-left: calc(0% + 1.875rem / 2); }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .large-offset-1 {
    margin-left: calc(8.33333% + 1.875rem / 2); }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .large-offset-2 {
    margin-left: calc(16.66667% + 1.875rem / 2); }
  .large-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .large-offset-3 {
    margin-left: calc(25% + 1.875rem / 2); }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .large-offset-4 {
    margin-left: calc(33.33333% + 1.875rem / 2); }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .large-offset-5 {
    margin-left: calc(41.66667% + 1.875rem / 2); }
  .large-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .large-offset-6 {
    margin-left: calc(50% + 1.875rem / 2); }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .large-offset-7 {
    margin-left: calc(58.33333% + 1.875rem / 2); }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .large-offset-8 {
    margin-left: calc(66.66667% + 1.875rem / 2); }
  .large-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .large-offset-9 {
    margin-left: calc(75% + 1.875rem / 2); }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .large-offset-10 {
    margin-left: calc(83.33333% + 1.875rem / 2); }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .large-offset-11 {
    margin-left: calc(91.66667% + 1.875rem / 2); } }

.grid-y {
  display: flex;
  flex-flow: column nowrap; }
  .grid-y > .cell {
    height: auto;
    max-height: none; }
  .grid-y > .auto {
    height: auto; }
  .grid-y > .shrink {
    height: auto; }
  .grid-y > .small-shrink, .grid-y > .small-full, .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
    flex-basis: auto; }
  @media print, screen and (min-width: 40em) {
    .grid-y > .medium-shrink, .grid-y > .medium-full, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
      flex-basis: auto; } }
  @media print, screen and (min-width: 64em) {
    .grid-y > .large-shrink, .grid-y > .large-full, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
      flex-basis: auto; } }
  .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
    flex: 0 0 auto; }
  .grid-y > .small-1 {
    height: 8.33333%; }
  .grid-y > .small-2 {
    height: 16.66667%; }
  .grid-y > .small-3 {
    height: 25%; }
  .grid-y > .small-4 {
    height: 33.33333%; }
  .grid-y > .small-5 {
    height: 41.66667%; }
  .grid-y > .small-6 {
    height: 50%; }
  .grid-y > .small-7 {
    height: 58.33333%; }
  .grid-y > .small-8 {
    height: 66.66667%; }
  .grid-y > .small-9 {
    height: 75%; }
  .grid-y > .small-10 {
    height: 83.33333%; }
  .grid-y > .small-11 {
    height: 91.66667%; }
  .grid-y > .small-12 {
    height: 100%; }
  @media print, screen and (min-width: 40em) {
    .grid-y > .medium-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .medium-shrink, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
      flex: 0 0 auto; }
    .grid-y > .medium-shrink {
      height: auto; }
    .grid-y > .medium-1 {
      height: 8.33333%; }
    .grid-y > .medium-2 {
      height: 16.66667%; }
    .grid-y > .medium-3 {
      height: 25%; }
    .grid-y > .medium-4 {
      height: 33.33333%; }
    .grid-y > .medium-5 {
      height: 41.66667%; }
    .grid-y > .medium-6 {
      height: 50%; }
    .grid-y > .medium-7 {
      height: 58.33333%; }
    .grid-y > .medium-8 {
      height: 66.66667%; }
    .grid-y > .medium-9 {
      height: 75%; }
    .grid-y > .medium-10 {
      height: 83.33333%; }
    .grid-y > .medium-11 {
      height: 91.66667%; }
    .grid-y > .medium-12 {
      height: 100%; } }
  @media print, screen and (min-width: 64em) {
    .grid-y > .large-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .large-shrink, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
      flex: 0 0 auto; }
    .grid-y > .large-shrink {
      height: auto; }
    .grid-y > .large-1 {
      height: 8.33333%; }
    .grid-y > .large-2 {
      height: 16.66667%; }
    .grid-y > .large-3 {
      height: 25%; }
    .grid-y > .large-4 {
      height: 33.33333%; }
    .grid-y > .large-5 {
      height: 41.66667%; }
    .grid-y > .large-6 {
      height: 50%; }
    .grid-y > .large-7 {
      height: 58.33333%; }
    .grid-y > .large-8 {
      height: 66.66667%; }
    .grid-y > .large-9 {
      height: 75%; }
    .grid-y > .large-10 {
      height: 83.33333%; }
    .grid-y > .large-11 {
      height: 91.66667%; }
    .grid-y > .large-12 {
      height: 100%; } }

.grid-padding-y .grid-padding-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-y .grid-padding-y {
      margin-top: -0.9375rem;
      margin-bottom: -0.9375rem; } }

.grid-padding-y > .cell {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-y > .cell {
      padding-top: 0.9375rem;
      padding-bottom: 0.9375rem; } }

.grid-margin-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y {
      margin-top: -0.9375rem;
      margin-bottom: -0.9375rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 1.25rem);
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .cell {
      height: calc(100% - 1.875rem);
      margin-top: 0.9375rem;
      margin-bottom: 0.9375rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.25rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.25rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.25rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.25rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.25rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.25rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.25rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.25rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.25rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.25rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.25rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.25rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 1.875rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 1.875rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 1.875rem); } }

.grid-frame {
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100vw; }

.cell .grid-frame {
  width: 100%; }

.cell-block {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }

.cell-block-y {
  overflow-y: auto;
  max-height: 100%;
  min-height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }

.cell-block-container {
  display: flex;
  flex-direction: column;
  max-height: 100%; }
  .cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap; }

@media print, screen and (min-width: 40em) {
  .medium-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .medium-grid-frame {
    width: 100%; }
  .medium-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .medium-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .medium-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .medium-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    min-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; } }

@media print, screen and (min-width: 64em) {
  .large-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .large-grid-frame {
    width: 100%; }
  .large-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .large-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .large-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .large-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    min-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; } }

.grid-y.grid-frame {
  width: auto;
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100vh; }

@media print, screen and (min-width: 40em) {
  .grid-y.medium-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

@media print, screen and (min-width: 64em) {
  .grid-y.large-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

.cell .grid-y.grid-frame {
  height: 100%; }

@media print, screen and (min-width: 40em) {
  .cell .grid-y.medium-grid-frame {
    height: 100%; } }

@media print, screen and (min-width: 64em) {
  .cell .grid-y.large-grid-frame {
    height: 100%; } }

.grid-margin-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y {
      margin-top: -0.9375rem;
      margin-bottom: -0.9375rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 1.25rem);
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .cell {
      height: calc(100% - 1.875rem);
      margin-top: 0.9375rem;
      margin-bottom: 0.9375rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.25rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.25rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.25rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.25rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.25rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.25rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.25rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.25rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.25rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.25rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.25rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.25rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 1.875rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 1.875rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 1.875rem); } }

.grid-frame.grid-margin-y {
  height: calc(100vh + 1.25rem); }
  @media print, screen and (min-width: 40em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 1.875rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 1.875rem); } }

@media print, screen and (min-width: 40em) {
  .grid-margin-y.medium-grid-frame {
    height: calc(100vh + 1.875rem); } }

@media print, screen and (min-width: 64em) {
  .grid-margin-y.large-grid-frame {
    height: calc(100vh + 1.875rem); } }

/*
//Browsers
ie,
edge,
chrome,
opera,
firefox,
safari,
vivaldi,

//Specific Browsers
chromeIOS,
ieMobile,

//Platforms
windows,
mac,
linux,
android,
blackberry,
ios,

//Type
desktop,
mobile,
*/
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  color: #2a6485;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  margin-bottom: 0 !important; }
  @media screen and (min-width: 80em) {
    body {
      font-size: 16px;
      font-size: 1rem; } }

img {
  max-width: 100%;
  height: auto; }

strong {
  font-weight: 700; }

em {
  font-style: italic; }

picture {
  display: block;
  overflow: hidden; }
  picture img {
    display: inline-block;
    margin: auto;
    width: 100%;
    filter: blur(10px);
    transition: filter 0.15s; }
    picture img.lazyloaded {
      filter: blur(0px); }

svg {
  color: inherit;
  fill: currentColor; }

.menu-local-tasks ul li:first-child {
  display: none; }

.menu-local-tasks ul li {
  margin-bottom: 10px; }

.node-preview-container {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 999; }

.tabs {
  position: fixed;
  bottom: 0;
  left: 0;
  color: #2a6485;
  z-index: 99;
  background: #fd7373;
  border-radius: 0 3px 0 0; }
  .tabs li {
    display: inline; }
  .tabs a {
    display: inline-block;
    padding: 8px 12px 6px;
    font-size: 12px;
    color: #2a6485;
    text-decoration: none; }
    .tabs a:hover, .tabs a.active {
      border-bottom: 2px solid #2a6485; }

.element-invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px; }

.element-invisible.element-focusable:active, .element-invisible.element-focusable:focus {
  position: static !important;
  clip: auto;
  overflow: visible;
  height: auto; }

.contentinfo {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: #2a6485;
  z-index: 99;
  padding: 20px;
  box-sizing: border-box;
  color: #fff;
  max-width: 500px;
  text-align: center;
  cursor: pointer;
  line-height: 1.5em;
  transition: opacity 0.15s; }
  @media print, screen and (min-width: 64em) {
    .contentinfo {
      padding: 20px 50px;
      bottom: 20px;
      font-size: 15px; } }
  .contentinfo--error {
    background: #de3b2f; }
  .contentinfo--hidden {
    opacity: 0;
    pointer-events: none; }
  .contentinfo:after {
    content: '✕';
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.2);
    width: 22px;
    height: 22px;
    font-size: 16px;
    text-align: center;
    line-height: 22px;
    color: #fff; }
  .contentinfo a {
    color: #fff;
    text-decoration: none;
    font-weight: 600; }

.form-item-view-mode {
  display: none !important; }

.visually-hidden {
  display: none !important; }

a#edit-backlink, .menu-local-tasks ul li a {
  display: none; }
  @media print, screen and (min-width: 64em) {
    a#edit-backlink, .menu-local-tasks ul li a {
      display: inline-block;
      background: #041bb9;
      padding: 10px 25px;
      border-radius: 22px;
      border: 1px solid white;
      color: white;
      text-decoration: none;
      font-weight: bold; }
      a#edit-backlink:hover, .menu-local-tasks ul li a:hover {
        background: #031487; } }

#toolbar-administration {
  display: none; }
  @media print, screen and (min-width: 64em) {
    #toolbar-administration {
      display: block; } }

body.toolbar-tray-open.toolbar-vertical.toolbar-fixed {
  margin-left: 0 !important; }
  @media print, screen and (min-width: 64em) {
    body.toolbar-tray-open.toolbar-vertical.toolbar-fixed {
      margin-left: 240px !important;
      margin-left: 15rem !important; } }

.adminimal-admin-toolbar nav.menu-local-tasks {
  position: fixed;
  z-index: 499;
  box-sizing: border-box;
  width: 10%;
  padding: 10px;
  bottom: 0; }

#toolbar-administration {
  /*display: none;*/ }

.visually-hidden {
  display: none; }

.isFrench {
  display: none !important; }

.isNotFrench {
  display: none !important; }

.ck-content.ck-editor__editable {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  color: #2a6485;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  margin: 20px !important;
  line-height: 1.4em; }
  @media screen and (min-width: 80em) {
    .ck-content.ck-editor__editable {
      font-size: 16px;
      font-size: 1rem; } }
  .ck-content.ck-editor__editable img {
    max-width: 100%;
    height: auto; }
  .ck-content.ck-editor__editable strong {
    font-weight: 600; }
  .ck-content.ck-editor__editable em {
    font-style: italic; }
  .ck-content.ck-editor__editable .a-text {
    line-height: 1.4em; }
    .ck-content.ck-editor__editable .a-text--primary {
      color: #2a6485; }
    .ck-content.ck-editor__editable .a-text--secondary {
      color: #fd7373; }
  .ck-content.ck-editor__editable .a-heading {
    display: block;
    font-family: 'Muli', sans-serif;
    line-height: 1.3em;
    font-weight: 700;
    color: #2a6485; }
    .ck-content.ck-editor__editable .a-heading--1 {
      font-size: 32px;
      font-size: 2rem;
      margin-bottom: 20px;
      line-height: 1.4em; }
      @media screen and (min-width: 80em) {
        .ck-content.ck-editor__editable .a-heading--1 {
          font-size: 50px;
          font-size: 3.125rem;
          line-height: 1.1em; } }
    .ck-content.ck-editor__editable .a-heading--2 {
      font-size: 26px;
      font-size: 1.625rem;
      margin-top: 40px;
      margin-bottom: 40px; }
    .ck-content.ck-editor__editable .a-heading--3 {
      font-size: 17px;
      font-size: 1.0625rem;
      margin-top: 20px;
      margin-bottom: 10px;
      line-height: 1.2em; }
      @media screen and (min-width: 80em) {
        .ck-content.ck-editor__editable .a-heading--3 {
          font-size: 18px;
          font-size: 1.125rem;
          margin-bottom: 20px; } }
    .ck-content.ck-editor__editable .a-heading--4 {
      font-size: 18px;
      font-size: 1.125rem;
      font-weight: 600;
      margin-top: 20px;
      margin-bottom: 20px; }
      @media print, screen and (min-width: 64em) {
        .ck-content.ck-editor__editable .a-heading--4 {
          font-size: 20px;
          font-size: 1.25rem; } }
    .ck-content.ck-editor__editable .a-heading--white {
      color: #fff; }
    .ck-content.ck-editor__editable .a-heading--primary {
      color: #2a6485; }
    .ck-content.ck-editor__editable .a-heading--secondary {
      color: #fd7373; }
    .ck-content.ck-editor__editable .a-heading--no-top {
      margin-top: 0; }
    .ck-content.ck-editor__editable .a-heading--no-bot {
      margin-bottom: 0; }
    .ck-content.ck-editor__editable .a-heading--underline {
      position: relative;
      padding-bottom: 20px; }
      .ck-content.ck-editor__editable .a-heading--underline:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 4px;
        width: 40px;
        background: #fd7373; }
      .ck-content.ck-editor__editable .a-heading--underline--white:after {
        background: #fff; }
    .ck-content.ck-editor__editable .a-heading--underline-tertiary:after {
      background: #7fbad4; }
    .ck-content.ck-editor__editable .a-heading--centered {
      text-align: center; }
      .ck-content.ck-editor__editable .a-heading--centered:after {
        left: 50%;
        transform: translateX(-50%); }
    .ck-content.ck-editor__editable .a-heading--slim {
      font-weight: 400; }
    .ck-content.ck-editor__editable .a-heading__sub {
      display: block; }
  .ck-content.ck-editor__editable p {
    margin-bottom: 20px; }
  .ck-content.ck-editor__editable ul {
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: 400; }
    @media print, screen and (min-width: 64em) {
      .ck-content.ck-editor__editable ul {
        margin-left: 1.25em; } }
  .ck-content.ck-editor__editable li {
    list-style: none;
    position: relative;
    margin-bottom: 20px;
    padding-left: 30px;
    color: #7fbad4; }
    .ck-content.ck-editor__editable li:before {
      content: " ";
      width: 17px;
      height: 17px;
      position: absolute;
      background-image: url("../../src/svg/triangle.svg");
      background-repeat: no-repeat;
      background-size: contain;
      top: 0.2em;
      left: 0; }
    .ck-content.ck-editor__editable li:last-child {
      margin-bottom: 0px; }
  .ck-content.ck-editor__editable a:not(.a-btn) {
    color: #2a6485; }
  .ck-content.ck-editor__editable h2 {
    font-size: 26px;
    font-size: 1.625rem;
    margin-top: 40px;
    margin-bottom: 40px;
    position: relative;
    padding-bottom: 20px; }
    .ck-content.ck-editor__editable h2:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 4px;
      width: 40px;
      background: #fd7373; }
  .ck-content.ck-editor__editable h3 {
    font-size: 17px;
    font-size: 1.0625rem;
    font-weight: 900;
    margin-top: 20px;
    margin-bottom: 10px;
    line-height: 1.3em; }
    @media screen and (min-width: 80em) {
      .ck-content.ck-editor__editable h3 {
        font-size: 18px;
        font-size: 1.125rem;
        margin-top: 40px;
        margin-bottom: 20px; } }
  .ck-content.ck-editor__editable blockquote, .ck-content.ck-editor__editable q {
    position: relative;
    width: 70%;
    margin: 0 auto;
    text-align: center;
    color: #2a6485;
    font-weight: 600; }
    @media print, screen and (min-width: 64em) {
      .ck-content.ck-editor__editable blockquote, .ck-content.ck-editor__editable q {
        width: 50%; } }
    .ck-content.ck-editor__editable blockquote p, .ck-content.ck-editor__editable q p {
      display: inline;
      background-image: linear-gradient(to bottom, white 60%, #d8f1fc 40%);
      padding: 5px 10px;
      line-height: 44px;
      font-size: 20px; }
    .ck-content.ck-editor__editable blockquote:before, .ck-content.ck-editor__editable blockquote:after, .ck-content.ck-editor__editable q:before, .ck-content.ck-editor__editable q:after {
      position: absolute;
      color: #fd7373;
      font-size: 25px; }
    .ck-content.ck-editor__editable blockquote:before, .ck-content.ck-editor__editable q:before {
      content: "«";
      top: -5px;
      left: -20px; }
    .ck-content.ck-editor__editable blockquote:after, .ck-content.ck-editor__editable q:after {
      content: "»";
      bottom: -5px;
      right: -20px; }

.a-wrapper {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box; }
  @media print, screen and (min-width: 35.5em) {
    .a-wrapper {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  @media print, screen and (min-width: 48em) {
    .a-wrapper {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  @media screen and (min-width: 80em) {
    .a-wrapper {
      padding-right: 0;
      padding-left: 0; } }

.a-heading {
  display: block;
  font-family: 'Muli', sans-serif;
  line-height: 1.3em;
  font-weight: 700;
  color: #2a6485; }
  .a-heading--1 {
    font-size: 32px;
    font-size: 2rem;
    margin-bottom: 20px;
    line-height: 1.4em; }
    @media screen and (min-width: 80em) {
      .a-heading--1 {
        font-size: 50px;
        font-size: 3.125rem;
        line-height: 1.1em; } }
  .a-heading--2 {
    font-size: 26px;
    font-size: 1.625rem;
    margin-top: 40px;
    margin-bottom: 40px; }
  .a-heading--3 {
    font-size: 17px;
    font-size: 1.0625rem;
    margin-top: 20px;
    margin-bottom: 10px;
    line-height: 1.2em; }
    @media screen and (min-width: 80em) {
      .a-heading--3 {
        font-size: 18px;
        font-size: 1.125rem;
        margin-bottom: 20px; } }
  .a-heading--4 {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px; }
    @media print, screen and (min-width: 64em) {
      .a-heading--4 {
        font-size: 20px;
        font-size: 1.25rem; } }
  .a-heading--white {
    color: #fff; }
  .a-heading--primary {
    color: #2a6485; }
  .a-heading--secondary {
    color: #fd7373; }
  .a-heading--no-top {
    margin-top: 0; }
  .a-heading--no-bot {
    margin-bottom: 0; }
  .a-heading--underline {
    position: relative;
    padding-bottom: 20px; }
    .a-heading--underline:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 4px;
      width: 40px;
      background: #fd7373; }
    .a-heading--underline--white:after {
      background: #fff; }
  .a-heading--underline-tertiary:after {
    background: #7fbad4; }
  .a-heading--centered {
    text-align: center; }
    .a-heading--centered:after {
      left: 50%;
      transform: translateX(-50%); }
  .a-heading--slim {
    font-weight: 400; }
  .a-heading__sub {
    display: block; }

.a-text {
  line-height: 1.4em; }
  .a-text--black {
    color: black; }
  .a-text--white {
    color: #fff; }
  .a-text--primary {
    color: #2a6485; }
  .a-text--secondary {
    color: #fd7373; }
  .a-text--tertiary {
    color: #7fbad4; }
  .a-text--grey-dark {
    color: #d8f1fc; }
  .a-text--fat {
    font-size: 15px;
    font-weight: 600; }
    @media screen and (min-width: 80em) {
      .a-text--fat {
        font-size: 18px; } }
  .a-text--slim {
    font-size: 12px;
    font-size: 0.75rem; }
    @media print, screen and (min-width: 64em) {
      .a-text--slim {
        font-size: 14px;
        font-size: 0.875rem; } }
  .a-text--centered {
    text-align: center; }
  .a-text--article strong {
    color: #2a6485; }
  .a-text--expertise {
    margin-top: 40px; }
    .a-text--expertise ul {
      margin-top: 40px; }
    .a-text--expertise li {
      color: #7fbad4; }
  .a-text p {
    margin-bottom: 20px; }
  .a-text ul {
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: 400; }
    @media print, screen and (min-width: 64em) {
      .a-text ul {
        margin-left: 1.25em; } }
  .a-text li {
    list-style: none;
    position: relative;
    margin-bottom: 20px;
    padding-left: 30px;
    color: #7fbad4; }
    .a-text li:before {
      content: " ";
      width: 17px;
      height: 17px;
      position: absolute;
      background-image: url("../../src/svg/triangle.svg");
      background-repeat: no-repeat;
      background-size: contain;
      top: 0.2em;
      left: 0; }
    .a-text li:last-child {
      margin-bottom: 0px; }
  .a-text a:not(.a-btn) {
    color: #2a6485; }
  .a-text h2 {
    font-size: 26px;
    font-size: 1.625rem;
    margin-top: 40px;
    margin-bottom: 40px;
    position: relative;
    padding-bottom: 20px; }
    .a-text h2:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 4px;
      width: 40px;
      background: #fd7373; }
  .a-text h3 {
    font-size: 17px;
    font-size: 1.0625rem;
    font-weight: 900;
    margin-top: 20px;
    margin-bottom: 10px;
    line-height: 1.3em; }
    @media screen and (min-width: 80em) {
      .a-text h3 {
        font-size: 18px;
        font-size: 1.125rem;
        margin-top: 40px;
        margin-bottom: 20px; } }
  .a-text blockquote, .a-text q {
    position: relative;
    width: 70%;
    margin: 0 auto;
    text-align: center;
    color: #2a6485;
    font-weight: 600; }
    @media print, screen and (min-width: 64em) {
      .a-text blockquote, .a-text q {
        width: 50%; } }
    .a-text blockquote p, .a-text q p {
      display: inline;
      background-image: linear-gradient(to bottom, white 60%, #d8f1fc 40%);
      padding: 5px 10px;
      line-height: 44px;
      font-size: 20px; }
    .a-text blockquote:before, .a-text blockquote:after, .a-text q:before, .a-text q:after {
      position: absolute;
      color: #fd7373;
      font-size: 25px; }
    .a-text blockquote:before, .a-text q:before {
      content: "«";
      top: -5px;
      left: -20px; }
    .a-text blockquote:after, .a-text q:after {
      content: "»";
      bottom: -5px;
      right: -20px; }
  .a-text > *:first-child {
    margin-top: 0; }
  .a-text > *:last-child {
    margin-bottom: 0; }

.a-btn,
input[type="submit"] {
  font-family: "Muli", sans-serif;
  display: inline-block;
  height: 40px;
  line-height: 36px;
  padding-right: 20px;
  padding-left: 20px;
  background: #7fbad4;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  position: relative;
  border: 2px solid #7fbad4;
  box-sizing: border-box;
  cursor: pointer; }
  @media print, screen and (min-width: 48em) {
    .a-btn,
    input[type="submit"] {
      height: 46px;
      line-height: 42px;
      padding-left: 40px; } }
  @media print, screen and (min-width: 64em) {
    .a-btn,
    input[type="submit"] {
      font-size: 16px; } }
  @media screen and (min-width: 80em) {
    .a-btn,
    input[type="submit"] {
      transition: padding 0.25s; } }
  @media screen and (min-width: 80em) {
    .a-btn:hover,
    input[type="submit"]:hover {
      padding-right: 40px;
      padding-left: 20px; } }
  @media screen and (min-width: 80em) {
    .a-btn:hover:before,
    input[type="submit"]:hover:before {
      left: 0;
      opacity: 0; } }
  @media screen and (min-width: 80em) {
    .a-btn:hover:after,
    input[type="submit"]:hover:after {
      right: 20px;
      opacity: 1; } }

@media print, screen and (min-width: 48em) {
  .a-btn:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 20px;
    height: 7px;
    width: 7px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    box-sizing: border-box;
    transform: translate(-50%, -50%) rotate(45deg); } }

@media screen and (min-width: 80em) {
  .a-btn:before {
    transition: left 0.25s, opacity 0.25s; } }

@media screen and (min-width: 80em) {
  .a-btn:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    height: 7px;
    width: 7px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    box-sizing: border-box;
    transform: translateY(-50%) rotate(45deg);
    opacity: 0;
    transition: right 0.25s, opacity 0.25s; } }

.a-btn--tertiary {
  background-color: #7fbad4;
  border-color: #7fbad4; }

.a-btn--border {
  border: 2px solid currentColor;
  background: transparent;
  color: #2a6485; }
  .a-btn--border:before {
    color: inherit;
    border-color: currentColor; }
  .a-btn--border:after {
    color: inherit;
    border-color: currentColor; }
  .a-btn--border-white {
    color: white;
    background: transparent; }
  .a-btn--border-tertiary {
    color: #7fbad4;
    border-width: 1px; }

.a-btn--top {
  margin-top: 40px; }

@media print, screen and (min-width: 48em) {
  input[type="submit"] {
    line-height: 40px; } }

.webform-button--submit-container {
  display: inline-block;
  position: relative; }
  @media screen and (min-width: 80em) {
    .webform-button--submit-container:hover:before {
      left: 0;
      opacity: 0; } }
  @media screen and (min-width: 80em) {
    .webform-button--submit-container:hover:after {
      right: 20px;
      opacity: 1; } }
  @media print, screen and (min-width: 48em) {
    .webform-button--submit-container:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 20px;
      z-index: 1;
      height: 7px;
      width: 7px;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      box-sizing: border-box;
      transform: translate(-50%, -50%) rotate(45deg); } }
  @media screen and (min-width: 80em) {
    .webform-button--submit-container:before {
      transition: left 0.25s, opacity 0.25s; } }
  @media screen and (min-width: 80em) {
    .webform-button--submit-container:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      z-index: 1;
      height: 7px;
      width: 7px;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      box-sizing: border-box;
      transform: translateY(-50%) rotate(45deg);
      opacity: 0;
      transition: right 0.25s, opacity 0.25s; } }

.a-link {
  display: inline-block;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 600;
  color: #2a6485;
  text-decoration: none;
  position: relative;
  outline: none;
  margin-top: 40px;
  padding: 5px 0; }
  @media print, screen and (min-width: 64em) {
    .a-link {
      font-size: 16px; } }
  @media screen and (min-width: 80em) {
    .a-link {
      transition: color 0.15s; } }
  @media screen and (min-width: 80em) {
    .a-link:hover {
      color: #fd7373; } }
  .a-link:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -3px;
    left: 0;
    height: 2px;
    background: #fd7373; }
    @media screen and (min-width: 80em) {
      .a-link:after {
        transition: height 0.15s; } }
  .a-link--white:after {
    background: #fff; }

.a-image img {
  display: inline-block;
  margin: auto; }

.a-video__container {
  position: relative;
  z-index: 1;
  padding-bottom: 56.25%;
  margin: auto; }

.a-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f3f8fb; }

.a-banner {
  background: #fd7373;
  color: #fff;
  line-height: 24px; }
  @media print, screen and (min-width: 64em) {
    .a-banner {
      display: flex;
      flex-flow: row wrap;
      display: flex;
      justify-content: center;
      height: 42px;
      line-height: 42px; } }
  .a-banner__container {
    flex: 0 0 auto;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    position: relative; }
    @media screen and (min-width: 80em) {
      .a-banner__container {
        padding: 0 20px; } }
  .a-banner__title {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    font-size: 0.875rem; }
  .a-banner__link {
    display: none; }
    @media print, screen and (min-width: 48em) {
      .a-banner__link {
        display: inline-block;
        float: right;
        font-size: 14px;
        font-size: 0.875rem;
        color: #fff;
        text-decoration: none;
        position: relative;
        padding-left: 15px;
        margin-left: 40px; } }
    @media screen and (min-width: 80em) {
      .a-banner__link:hover {
        text-decoration: underline; } }
    @media print, screen and (min-width: 48em) {
      .a-banner__link:before {
        content: '';
        width: 5px;
        height: 5px;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%) rotate(45deg); } }
  .a-banner__close {
    display: none; }

.a-breadcrumb {
  position: relative;
  z-index: 1; }
  .a-breadcrumb nav {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
    font-size: 0.75rem;
    padding: 20px;
    padding-right: 0;
    box-sizing: border-box;
    max-width: 100%;
    white-space: nowrap;
    overflow: auto; }
    @media print, screen and (min-width: 64em) {
      .a-breadcrumb nav {
        top: 70px;
        font-size: 14px;
        font-size: 0.875rem; } }
    .a-breadcrumb nav li {
      display: inline-block;
      color: #fff; }
      .a-breadcrumb nav li:last-child {
        margin-right: 20px; }
        .a-breadcrumb nav li:last-child:after {
          display: none; }
      .a-breadcrumb nav li:after {
        content: '>';
        display: inline-block;
        margin: 0 5px; }
    .a-breadcrumb nav a {
      color: #fff;
      font-weight: 700;
      text-decoration: none; }

.a-tags {
  text-align: center; }
  .a-tags__container {
    margin-bottom: 5px; }
  .a-tags__tag {
    display: inline-block;
    color: #fff;
    padding: 10px;
    border: 1px solid #fff;
    margin: 0 5px 10px;
    cursor: pointer;
    user-select: none; }
    @media screen and (min-width: 80em) {
      .a-tags__tag {
        transition: background-color 0.15s, border-color 0.15s; } }
    @media screen and (min-width: 80em) {
      .a-tags__tag:hover {
        background: #7fbad4; } }
    .a-tags__tag--active {
      background: #2a6485 !important;
      border-color: #2a6485 !important; }
  .a-tags .a-btn--apply {
    display: none; }

.a-burger {
  width: 50px;
  height: 50px;
  background: #2a6485;
  border: 1px solid rgba(255, 255, 255, 0.15);
  position: fixed;
  z-index: 3;
  bottom: 20px;
  right: 20px;
  border-radius: 50%; }
  .a-burger__container {
    height: 16px;
    width: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  @media print, screen and (min-width: 64em) {
    .a-burger {
      display: none; } }
  .a-burger__open {
    height: 100%;
    overflow: hidden; }
    .a-burger__open:before, .a-burger__open:after, .a-burger__open span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
      height: 2px;
      width: 100%;
      background: #fff;
      transition: width 0.25s 0.25s; }
    .a-burger__open span {
      margin-top: -1px; }
    .a-burger__open:before {
      content: '';
      top: 0; }
    .a-burger__open:after {
      content: '';
      top: auto;
      bottom: 0; }
  .a-burger__close {
    height: 100%;
    overflow: hidden; }
    .a-burger__close:before, .a-burger__close:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 2px;
      width: 0%;
      background: #fff;
      transition: width 0.25s; }
    .a-burger__close:before {
      transform: translate(-50%, -50%) rotate(45deg); }
    .a-burger__close:after {
      transform: translate(-50%, -50%) rotate(-45deg); }

.o-body--menu-on .a-burger__open:before, .o-body--menu-on .a-burger__open:after, .o-body--menu-on .a-burger__open span {
  width: 0%;
  transition: width 0.25s; }

.o-body--menu-on .a-burger__close:before, .o-body--menu-on .a-burger__close:after {
  width: 120%;
  transition: width 0.25s 0.25s; }

.a-text a.a-tag, .a-tag {
  text-decoration: none; }
  .a-text a.a-tag--tertiary, .a-tag--tertiary {
    color: #7fbad4; }
  .a-text a.a-tag--quaternary, .a-tag--quaternary {
    color: #9bb1c8; }

.js-pager__items {
  color: #fff;
  font-weight: bold; }
  .js-pager__items li {
    display: inline; }
    .js-pager__items li a {
      text-decoration: none; }

.m-gallery {
  display: flex;
  flex-flow: row wrap;
  margin-right: -20px;
  margin-bottom: -40px;
  margin-left: -20px; }
  .m-gallery__item {
    flex: 0 0 auto;
    width: calc(50% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-bottom: 40px; }
    @media print, screen and (min-width: 48em) {
      .m-gallery__item {
        flex: 0 0 auto;
        width: calc(33.33333% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem; } }
  .m-gallery__image {
    background: #f3f8fb; }
  .m-gallery img {
    display: block;
    width: 100%; }

.m-cards {
  display: flex;
  flex-flow: row wrap;
  margin-right: -20px;
  margin-bottom: -40px;
  margin-left: -20px; }
  @media screen and (min-width: 80em) {
    .m-cards {
      margin-right: 0;
      margin-left: 0; } }
  .m-cards img {
    margin-bottom: 20px;
    width: auto; }
  .m-cards--values-text .m-cards__card {
    text-align: left; }
    @media print, screen and (min-width: 64em) {
      .m-cards--values-text .m-cards__card {
        flex: 0 0 auto;
        width: calc(50% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        padding-right: 20px;
        box-sizing: border-box; } }
  .m-cards--values {
    padding-left: 30px;
    padding-right: 30px; }
    .m-cards--values .m-cards__card {
      padding: 0 20px 20px;
      box-sizing: border-box;
      text-align: center; }
      @media print, screen and (min-width: 48em) {
        .m-cards--values .m-cards__card {
          flex: 0 0 auto;
          width: calc(33.33333% - 2.5rem);
          margin-left: 1.25rem;
          margin-right: 1.25rem; } }
      .m-cards--values .m-cards__card h3 {
        color: #fd7373;
        font-weight: bold; }
      .m-cards--values .m-cards__card .a-text {
        color: #fff;
        font-weight: bold; }
  .m-cards--keys .m-cards__card {
    text-align: center; }
    @media print, screen and (min-width: 48em) {
      .m-cards--keys .m-cards__card {
        flex: 0 0 auto;
        width: calc(33.33333% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem; } }
  .m-cards__card {
    flex: 0 0 auto;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-bottom: 40px; }
    @media print, screen and (min-width: 48em) {
      .m-cards__card {
        flex: 0 0 auto;
        width: calc(50% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem; } }
  .m-cards__stat {
    display: inline-block;
    font-family: 'Muli', sans-serif;
    font-size: 60px;
    font-size: 3.75rem;
    color: #fd7373;
    font-weight: 700;
    position: relative;
    z-index: 1;
    padding-bottom: 10px;
    box-sizing: border-box;
    margin-bottom: 20px; }
    .m-cards__stat:before {
      content: '';
      position: absolute;
      top: 50%;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 120%;
      min-width: 118px;
      z-index: -1;
      background: #fff; }

.m-logos {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: -40px; }
  .m-logos__logo {
    flex: 0 0 auto;
    width: calc(50% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    position: relative;
    margin-bottom: 40px; }
    @media print, screen and (min-width: 48em) {
      .m-logos__logo {
        flex: 0 0 auto;
        width: calc(33.33333% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem; } }
    @media print, screen and (min-width: 64em) {
      .m-logos__logo {
        flex: 0 0 auto;
        width: calc(25% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem; } }
  .m-logos img {
    display: inline-block;
    margin-right: auto;
    margin-bottom: 20px;
    margin-left: auto; }
  .m-logos__link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

@media print, screen and (min-width: 64em) {
  .m-nav-main {
    width: 100%;
    text-align: center;
    height: 100%;
    position: relative; } }

@media print, screen and (min-width: 64em) {
  .m-nav-main__item {
    display: inline-block;
    margin: 0 10px;
    height: 100%; } }

@media screen and (min-width: 85.375em) {
  .m-nav-main__item {
    margin: 0 15px; } }

@media print, screen and (min-width: 64em) {
  .m-nav-main__item:first-child {
    margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .m-nav-main__item:last-child {
    margin-right: 0; } }

@media print, screen and (min-width: 64em) {
  .m-nav-main__item:last-child .m-nav-main__link-blue {
    position: relative;
    z-index: 1;
    padding: 0 15px;
    color: #fff; } }

@media print, screen and (min-width: 64em) {
  .m-nav-main__item:last-child .m-nav-main__link-blue:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    z-index: -1;
    transform: translateY(-50%);
    height: 38px;
    background: #7fbad4; } }

@media print, screen and (min-width: 64em) {
  .m-nav-main__item:hover .m-nav-main__sub {
    transform: translate(-50%, 100%);
    opacity: 1; } }

@media print, screen and (min-width: 64em) {
  .m-nav-main__item--expanded {
    position: relative; } }

@media print, screen and (min-width: 64em) {
  .m-nav-main__item--expanded .m-nav-main__sub {
    background: #f3f8fb;
    padding: 20px; } }

@media print, screen and (min-width: 64em) {
  .m-nav-main__item--expanded .m-nav-main__sub-item {
    display: block;
    margin-bottom: 5px;
    padding: 0; } }

@media print, screen and (min-width: 64em) {
  .m-nav-main__item--expanded .m-nav-main__sub-item:last-child {
    margin-bottom: 0; } }

@media print, screen and (min-width: 64em) {
  .m-nav-main__item--expanded .m-nav-main__sub-link {
    display: block;
    font-family: "Muli", sans-serif;
    color: #2a6485;
    line-height: 1.2em;
    text-align: left;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 500;
    white-space: nowrap;
    text-decoration: none;
    padding: 5px 0;
    transition: color 0.15s; } }

@media screen and (min-width: 80em) {
  .m-nav-main__item--expanded .m-nav-main__sub-link {
    font-size: 16px;
    font-size: 1rem; } }

@media screen and (min-width: 80em) and (min-width: 64em) {
  .m-nav-main__item--expanded .m-nav-main__sub-link:hover {
    color: #7fbad4; } }

.m-nav-main__item--full .m-nav-main__link, .m-nav-main__item--expanded .m-nav-main__link {
  position: relative; }
  @media print, screen and (min-width: 64em) {
    .m-nav-main__item--full .m-nav-main__link, .m-nav-main__item--expanded .m-nav-main__link {
      padding-right: 18px; } }
  .m-nav-main__item--full .m-nav-main__link:before, .m-nav-main__item--expanded .m-nav-main__link:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 2px;
    height: 12px;
    background: #fff; }
    @media print, screen and (min-width: 64em) {
      .m-nav-main__item--full .m-nav-main__link:before, .m-nav-main__item--expanded .m-nav-main__link:before {
        display: none; } }
  .m-nav-main__item--full .m-nav-main__link:after, .m-nav-main__item--expanded .m-nav-main__link:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%) rotate(90deg);
    width: 2px;
    height: 12px;
    background: #fff; }
    @media print, screen and (min-width: 64em) {
      .m-nav-main__item--full .m-nav-main__link:after, .m-nav-main__item--expanded .m-nav-main__link:after {
        position: absolute;
        top: 50%;
        right: 0;
        width: 6px;
        height: 6px;
        background: transparent;
        border-right: 2px solid #7fbad4;
        border-bottom: 2px solid #7fbad4;
        transform: translateY(-75%) rotate(45deg); } }

.m-nav-main__item--opened .m-nav-main__link {
  border-bottom: none; }
  .m-nav-main__item--opened .m-nav-main__link:before {
    display: none; }

.m-nav-main__item--opened .m-nav-main__sub {
  display: block; }

.m-nav-main__link {
  display: block;
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  text-align: center;
  background-color: #2a6485;
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  font-size: 1.125rem;
  font-family: "Muli", sans-serif;
  font-weight: 500; }
  @media print, screen and (min-width: 64em) {
    .m-nav-main__link {
      display: inline-block;
      height: 100%;
      line-height: 70px;
      padding: 0;
      border: none;
      text-align: left;
      background: transparent;
      color: #2a6485;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      font-size: 14px;
      font-size: 0.875rem;
      transition: color 0.25s; } }
  @media screen and (min-width: 80em) {
    .m-nav-main__link {
      font-size: 16px;
      font-size: 1rem; } }
  .m-nav-main__link[href=""] {
    cursor: default; }

.m-nav-main__sub {
  display: none; }
  @media print, screen and (min-width: 64em) {
    .m-nav-main__sub {
      display: block;
      opacity: 0;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, -100%);
      z-index: 1;
      background: #fff;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
      transition: opacity 0.15s; } }
  @media print, screen and (min-width: 64em) {
    .m-nav-main__sub--full {
      width: 100%;
      padding-left: 30%;
      box-sizing: border-box;
      background: linear-gradient(to right, #f3f8fb 30%, #fff); } }
  .m-nav-main__sub--full .swiper-wrapper {
    display: block; }
    @media print, screen and (min-width: 64em) {
      .m-nav-main__sub--full .swiper-wrapper {
        display: flex; } }

@media print, screen and (min-width: 64em) {
  .m-nav-main__sub-item {
    display: flex;
    width: 100%;
    text-align: left;
    padding-top: 30px;
    padding-bottom: 30px;
    box-sizing: border-box; } }

.m-nav-main__sub-item a {
  display: block;
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: #fff;
  color: #2a6485;
  text-decoration: none;
  font-size: 16px;
  font-size: 1rem;
  font-family: "Muli", sans-serif;
  font-weight: 500; }
  @media print, screen and (min-width: 64em) {
    .m-nav-main__sub-item a {
      display: inline-block;
      font-size: 14px;
      font-size: 0.875rem;
      background: transparent;
      border-bottom: none;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      padding: 5px 0; } }
  @media screen and (min-width: 80em) {
    .m-nav-main__sub-item a {
      font-size: 16px;
      font-size: 1rem; } }

.m-nav-main__sub-item--opened .m-nav-main__sub-label:before {
  display: none; }

.m-nav-main__sub-item--opened .m-nav-main__sub-menu {
  display: block; }

.m-nav-main__sub-titles {
  display: none; }
  @media print, screen and (min-width: 64em) {
    .m-nav-main__sub-titles {
      display: block;
      position: absolute;
      top: 0;
      right: 70%;
      bottom: 0;
      left: 0;
      background: #7fbad4;
      text-align: left;
      padding-top: 20px;
      padding-bottom: 20px; } }

@media print, screen and (min-width: 64em) {
  .m-nav-main__sub-title {
    font-family: "Muli", sans-serif;
    font-weight: 500;
    color: #fff;
    line-height: 1.2em;
    font-size: 14px;
    font-size: 0.875rem;
    padding: 10px 25px 10px 20px;
    position: relative;
    transition: color 0.15s; } }

@media screen and (min-width: 80em) {
  .m-nav-main__sub-title {
    font-size: 16px;
    font-size: 1rem; } }

@media screen and (min-width: 80em) and (min-width: 64em) {
  .m-nav-main__sub-title:hover {
    olor: #2a6485; } }

@media print, screen and (min-width: 64em) {
  .m-nav-main__sub-title:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    height: 35px;
    width: 5px;
    background: #2a6485;
    opacity: 0;
    transition: opacity 0.15s; } }

@media print, screen and (min-width: 64em) {
  .m-nav-main__sub-title--active {
    color: #2a6485; } }

@media print, screen and (min-width: 64em) {
  .m-nav-main__sub-title--active:after {
    opacity: 1; } }

.m-nav-main__sub-label {
  position: relative;
  display: block;
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  text-align: center;
  background-color: #7fbad4;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  font-size: 1rem;
  font-family: "Muli", sans-serif;
  font-weight: 500; }
  @media print, screen and (min-width: 64em) {
    .m-nav-main__sub-label {
      display: none; } }
  .m-nav-main__sub-label:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 2px;
    height: 12px;
    background: #fff; }
  .m-nav-main__sub-label:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%) rotate(90deg);
    width: 2px;
    height: 12px;
    background: #fff; }

.m-nav-main__sub-content {
  display: none; }
  @media print, screen and (min-width: 64em) {
    .m-nav-main__sub-content {
      display: block;
      width: 50%;
      padding-right: 20px;
      padding-left: 20px;
      box-sizing: border-box; } }
  @media print, screen and (min-width: 64em) {
    .m-nav-main__sub-content .a-link {
      margin-top: 20px; } }

.m-nav-main__sub-menu {
  display: none; }
  @media print, screen and (min-width: 64em) {
    .m-nav-main__sub-menu {
      display: block;
      width: 50%;
      padding-right: 20px;
      padding-left: 10px;
      box-sizing: border-box; } }
  @media print, screen and (min-width: 64em) {
    .m-nav-main__sub-menu li {
      margin-bottom: 5px; } }
  .m-nav-main__sub-menu a {
    display: block;
    padding: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center;
    background-color: #fff;
    color: #2a6485;
    text-decoration: none;
    font-size: 16px;
    font-size: 1rem;
    font-family: "Muli", sans-serif;
    font-weight: 500;
    line-height: 1.25em; }
    @media print, screen and (min-width: 64em) {
      .m-nav-main__sub-menu a {
        background-color: transparent;
        display: inline-block;
        color: #7fbad4;
        text-decoration: none;
        padding: 5px 0;
        border-bottom: none;
        font-size: 14px;
        font-size: 0.875rem;
        text-align: left; } }
    @media screen and (min-width: 80em) {
      .m-nav-main__sub-menu a {
        font-size: 16px;
        font-size: 1rem; } }

@media screen and (min-width: 80em) and (min-width: 64em) {
  .m-nav-main__sub-menu a:hover {
    text-decoration: underline; } }
    @media print, screen and (min-width: 64em) {
      .m-nav-main__sub-menu a:first-child {
        padding-top: 0; } }

@media print, screen and (min-width: 64em) {
  .o-body--home.o-body--static .m-nav-main__link {
    color: #fff; } }

.m-nav-social {
  margin-top: 20px; }
  @media print, screen and (min-width: 64em) {
    .m-nav-social {
      margin-top: 40px; } }
  .m-nav-social__item {
    display: inline-block;
    margin-right: 5px; }
    .m-nav-social__item:last-child {
      margin-right: 0; }
  .m-nav-social__logo {
    width: 35px;
    height: 35px;
    fill: #7fbad4; }
    @media screen and (min-width: 80em) {
      .m-nav-social__logo:hover {
        fill: #fff; } }
    @media screen and (min-width: 80em) {
      .m-nav-social__logo {
        transition: fill 0.15s; } }
    .m-nav-social__logo--rss {
      width: 25px !important; }
  .m-nav-social--bloc svg {
    fill: #2a6485;
    width: 45px;
    height: 45px; }
    @media screen and (min-width: 80em) {
      .m-nav-social--bloc svg:hover {
        fill: #7fbad4; } }
  @media screen and (min-width: 80em) {
    .m-nav-social--bloc {
      transition: fill 0.15s; } }
  .m-nav-social--article {
    margin-top: 0;
    display: flex;
    align-items: center; }
    .m-nav-social--article p {
      font-size: 15px;
      font-size: 0.9375rem;
      color: #2a6485;
      margin-right: 15px; }
    .m-nav-social--article .m-nav-social {
      margin-top: 0; }
      @media print, screen and (min-width: 64em) {
        .m-nav-social--article .m-nav-social__logo:hover {
          fill: #2a6485; } }
    @media print, screen and (min-width: 64em) {
      .m-nav-social--article {
        justify-content: flex-end; } }

@media print, screen and (min-width: 64em) {
  .m-nav-footer, .m-nav-footer-en {
    display: flex;
    flex-flow: row wrap;
    margin-right: -20px;
    margin-left: -20px; } }

.m-nav-footer__item, .m-nav-footer-en__item {
  margin-bottom: 20px; }
  .m-nav-footer__item:last-child, .m-nav-footer-en__item:last-child {
    margin-bottom: 0;
    font-weight: 700; }
    .m-nav-footer__item:last-child .m-nav-footer__link, .m-nav-footer__item:last-child .m-nav-footer-en__link, .m-nav-footer-en__item:last-child .m-nav-footer__link, .m-nav-footer-en__item:last-child .m-nav-footer-en__link {
      display: none; }
  .m-nav-footer__item--expanded, .m-nav-footer-en__item--expanded {
    margin-bottom: 40px; }
    @media print, screen and (min-width: 64em) {
      .m-nav-footer__item--expanded, .m-nav-footer-en__item--expanded {
        flex: 0 0 auto;
        width: calc(25% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem; } }
    .m-nav-footer__item--expanded .m-nav-footer__link, .m-nav-footer__item--expanded .m-nav-footer-en__link, .m-nav-footer-en__item--expanded .m-nav-footer__link, .m-nav-footer-en__item--expanded .m-nav-footer-en__link {
      margin-bottom: 20px; }

.m-nav-footer__link, .m-nav-footer-en__link {
  display: inline-block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
  color: #fff;
  text-decoration: none; }
  @media screen and (min-width: 80em) {
    .m-nav-footer__link, .m-nav-footer-en__link {
      transition: color 0.15s; } }

.m-nav-footer__sub-item, .m-nav-footer-en__sub-item {
  margin-bottom: 10px; }
  @media print, screen and (min-width: 64em) {
    .m-nav-footer__sub-item, .m-nav-footer-en__sub-item {
      margin-bottom: 20px; } }
  .m-nav-footer__sub-item:last-child, .m-nav-footer-en__sub-item:last-child {
    margin-bottom: 0; }

.m-nav-footer__sub-link, .m-nav-footer-en__sub-link {
  font-size: 14px;
  font-size: 0.875rem;
  color: #fff;
  text-decoration: none; }

.m-nav-legal__item, .m-nav-legal-en__item {
  margin-bottom: 10px; }
  @media print, screen and (min-width: 48em) {
    .m-nav-legal__item, .m-nav-legal-en__item {
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 0; } }
  .m-nav-legal__item:last-child, .m-nav-legal-en__item:last-child {
    margin-bottom: 0; }

.m-nav-legal__link, .m-nav-legal-en__link {
  color: #7fbad4;
  font-size: 12px;
  font-size: 0.75rem; }
  @media screen and (min-width: 80em) {
    .m-nav-legal__link, .m-nav-legal-en__link {
      transition: color 0.15s; } }
  @media screen and (min-width: 80em) {
    .m-nav-legal__link:hover, .m-nav-legal-en__link:hover {
      color: #fff; } }

.form-item a {
  color: inherit; }

.m-form .captcha {
  margin-bottom: 20px; }

.m-form a {
  color: inherit; }

.m-form label {
  font-size: 13px;
  line-height: 1.3em; }
  @media print, screen and (min-width: 64em) {
    .m-form label {
      font-size: 15px; } }

.m-form .form-item {
  margin-bottom: 20px; }
  .m-form .form-item:last-child {
    margin-bottom: 0; }

.m-form input[type="text"],
.m-form input[type="password"],
.m-form input[type="email"],
.m-form input[type="tel"],
.m-form select,
.m-form textarea {
  display: block;
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #e1e8f3;
  color: #2a6485;
  outline: none; }
  @media print, screen and (min-width: 64em) {
    .m-form input[type="text"],
    .m-form input[type="password"],
    .m-form input[type="email"],
    .m-form input[type="tel"],
    .m-form select,
    .m-form textarea {
      padding: 15px 10px;
      font-size: 16px; } }

.m-form input[type="checkbox"],
.m-form input[type="radio"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: solid 2px #2a6485;
  vertical-align: middle;
  margin: 0;
  margin-top: -4px;
  margin-right: 5px;
  color: #fff;
  cursor: pointer;
  outline: none;
  position: relative; }
  .m-form input[type="checkbox"]:checked,
  .m-form input[type="radio"]:checked {
    background: #2a6485; }
    .m-form input[type="checkbox"]:checked:before,
    .m-form input[type="radio"]:checked:before {
      display: block; }
  .m-form input[type="checkbox"]:before,
  .m-form input[type="radio"]:before {
    content: "✔";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    display: none; }

.m-form input[type="radio"] {
  border-radius: 50%; }

.m-form input[type="submit"] {
  font-family: "Open Sans", sans-serif;
  outline: none;
  margin: 0;
  appearance: none; }

.m-form .form-item--error-message {
  color: #de3b2f;
  font-size: 14px;
  margin-top: 10px; }
  .m-form .form-item--error-message a {
    color: #2a6485; }

.m-form .description,
.m-form .field-rgpd-text,
.m-form .field-gdpr-text {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  color: #4e9bc7;
  line-height: 1.5em; }
  .m-form .description a,
  .m-form .field-rgpd-text a,
  .m-form .field-gdpr-text a {
    color: #2a6485; }

.m-form .form-item-rgpd label,
.m-form .form-item-newsletter-marketing label,
.m-form .form-item-marketing label {
  font-size: 12px;
  line-height: 1.2em; }

.m-form #edit-legal--2,
.m-form #edit-legal--3,
.m-form #edit-legal {
  font-size: 11px;
  font-size: 0.6875rem;
  text-align: justify; }
  .m-form #edit-legal--2 a,
  .m-form #edit-legal--3 a,
  .m-form #edit-legal a {
    color: #2a6485; }

.m-form input[type="text"],
.m-form input[type="password"],
.m-form input[type="email"],
.m-form input[type="tel"],
.m-form select,
.m-form textarea {
  color: black;
  font-weight: normal; }

.m-form--publication {
  color: #393939; }
  .m-form--publication iframe {
    max-width: 100%; }
  .m-form--publication .a-text {
    margin-top: 30px; }
  .m-form--publication .a-btn {
    background: #fd7373;
    color: #fff;
    border-color: #fd7373;
    margin-top: 20px; }
    @media print, screen and (min-width: 64em) {
      .m-form--publication .a-btn {
        margin-top: 30px; } }
    .m-form--publication .a-btn:before, .m-form--publication .a-btn:after {
      border-color: #fff; }

.m-form--recruitment .description, .m-form--application .description {
  display: none; }

.m-form--recruitment #edit-import-cv-upload, .m-form--recruitment #edit-cv-upload--2, .m-form--application #edit-import-cv-upload, .m-form--application #edit-cv-upload--2 {
  width: 100%;
  color: transparent;
  position: relative;
  margin-top: 5px; }
  .m-form--recruitment #edit-import-cv-upload::-webkit-file-upload-button, .m-form--recruitment #edit-cv-upload--2::-webkit-file-upload-button, .m-form--application #edit-import-cv-upload::-webkit-file-upload-button, .m-form--application #edit-cv-upload--2::-webkit-file-upload-button {
    visibility: hidden; }
  .m-form--recruitment #edit-import-cv-upload:focus, .m-form--recruitment #edit-cv-upload--2:focus, .m-form--application #edit-import-cv-upload:focus, .m-form--application #edit-cv-upload--2:focus {
    outline: none; }
  .m-form--recruitment #edit-import-cv-upload:before, .m-form--recruitment #edit-cv-upload--2:before, .m-form--application #edit-import-cv-upload:before, .m-form--application #edit-cv-upload--2:before {
    content: "Déposez un fichier ou cliquez pour l’importer";
    display: block;
    border: 1px solid #2a6485;
    border-radius: 3px;
    padding: 70px 8px 30px;
    outline: none;
    text-align: center;
    color: #2a6485;
    cursor: pointer;
    font-size: 17px; }
  .m-form--recruitment #edit-import-cv-upload:after, .m-form--recruitment #edit-cv-upload--2:after, .m-form--application #edit-import-cv-upload:after, .m-form--application #edit-cv-upload--2:after {
    content: "";
    background-image: url(../../src/svg/upload.svg);
    width: 30px;
    height: 30px;
    display: block;
    background-repeat: no-repeat;
    top: 30px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%); }

.m-form--recruitment #edit-import-cv .webform-button--submit-container, .m-form--recruitment #edit-cv--2 .webform-button--submit-container, .m-form--application #edit-import-cv .webform-button--submit-container, .m-form--application #edit-cv--2 .webform-button--submit-container {
  display: none; }

.form-item {
  /* &-rgpd {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: $xs-value;

    input[type="checkbox"] {
      flex: 1;
    }

    .description {
      flex: 11;
    }
  } */ }
  .form-item-rgpd, .form-item-gdpr, .form-item-newsletter-marketing {
    margin-top: 10px; }
    .form-item-rgpd input[type="checkbox"], .form-item-gdpr input[type="checkbox"], .form-item-newsletter-marketing input[type="checkbox"] {
      display: inline-block; }
    .form-item-rgpd .description, .form-item-gdpr .description, .form-item-newsletter-marketing .description {
      display: inline; }

.js-form-type-checkbox {
  margin-top: 10px; }
  .js-form-type-checkbox input[type="checkbox"] {
    display: inline-block; }
  .js-form-type-checkbox .description {
    display: inline; }

.m-values-text {
  margin-right: -20px;
  margin-left: -20px; }
  @media print, screen and (min-width: 64em) {
    .m-values-text {
      display: flex;
      flex-flow: row wrap; } }
  .m-values-text__left {
    flex: 0 0 auto;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-bottom: 40px; }
    @media print, screen and (min-width: 64em) {
      .m-values-text__left {
        flex: 0 0 auto;
        width: calc(41.66667% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem; } }
  .m-values-text__right {
    flex: 0 0 auto;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
    @media print, screen and (min-width: 64em) {
      .m-values-text__right {
        flex: 0 0 auto;
        width: calc(50% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        margin-left: calc(8.33333% + 2.5rem / 2); } }

.m-verbatim {
  position: relative; }
  @media print, screen and (min-width: 64em) {
    .m-verbatim:before, .m-verbatim:after {
      content: '';
      width: 47px;
      height: 47px;
      position: absolute;
      border-radius: 50%; } }
  @media print, screen and (min-width: 64em) {
    .m-verbatim:before {
      top: 15%;
      left: -220px;
      background: #7fbad4; } }
  @media print, screen and (min-width: 64em) {
    .m-verbatim:after {
      bottom: 0;
      right: -100px;
      background: #2a6485; } }
  .m-verbatim__logo img {
    display: block;
    width: 350px;
    margin: auto;
    margin-bottom: 40px; }
  .m-verbatim__logo .m-customers__item {
    margin: 0;
    width: 100%; }
  .m-verbatim__testimony {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.6;
    text-align: center; }
    @media print, screen and (min-width: 64em) {
      .m-verbatim__testimony {
        font-size: 17px;
        font-size: 1.0625rem;
        max-width: 478px;
        margin: auto; } }
  .m-verbatim__author {
    margin-top: 20px;
    text-align: center;
    font-size: 17px;
    font-size: 1.0625rem;
    font-weight: 700; }
  .m-verbatim__function {
    display: block;
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 400;
    color: #9bb1c8;
    margin-top: 10px; }
  .m-verbatim__portrait {
    display: none; }
    @media print, screen and (min-width: 48em) {
      .m-verbatim__portrait {
        display: block;
        width: 58px;
        position: absolute; } }
    @media print, screen and (min-width: 64em) {
      .m-verbatim__portrait--1 {
        top: 5%;
        right: -150px; } }
    @media print, screen and (min-width: 64em) {
      .m-verbatim__portrait--2 {
        top: 50%;
        right: -230px; } }
    @media print, screen and (min-width: 64em) {
      .m-verbatim__portrait--3 {
        top: 55%;
        left: -120px; } }

.m-customers {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-bottom: -40px; }
  @media print, screen and (min-width: 48em) {
    .m-customers--3 .m-customers__item {
      flex: 0 0 auto;
      width: calc(33.33333% - 2.5rem);
      margin-left: 1.25rem;
      margin-right: 1.25rem; } }
  @media print, screen and (min-width: 48em) {
    .m-customers--4 .m-customers__item {
      flex: 0 0 auto;
      width: calc(25% - 2.5rem);
      margin-left: 1.25rem;
      margin-right: 1.25rem; } }
  .m-customers__item {
    flex: 0 0 auto;
    width: calc(50% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-bottom: 40px; }
    .m-customers__item img {
      max-width: 260px;
      display: block; }

@media print, screen and (min-width: 64em) {
  .m-teams {
    display: flex;
    flex-flow: row wrap; } }

@media print, screen and (min-width: 64em) {
  .m-teams__left {
    flex: 0 0 auto;
    width: calc(33.33333% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem; } }

@media print, screen and (min-width: 64em) {
  .m-teams__right {
    flex: 0 0 auto;
    width: calc(58.33333% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-left: calc(8.33333% + 2.5rem / 2); } }

@media print, screen and (min-width: 64em) {
  .m-teams__link {
    display: block;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #7fbad4;
    text-decoration: none;
    color: black;
    font-weight: 700; } }

@media screen and (min-width: 80em) {
  .m-teams__link {
    transition: border-color 0.15s, color 0.15s; } }

@media screen and (min-width: 80em) {
  .m-teams__link:hover {
    color: #2a6485;
    border-color: #2a6485; } }

@media print, screen and (min-width: 64em) {
  .m-teams__link:first-child {
    margin-top: -20px; } }

.m-teams__link--active {
  color: #fd7373;
  pointer-events: none;
  border-color: #fd7373; }

.m-teams__team {
  overflow: hidden; }

.m-teams__label {
  position: relative;
  display: block;
  width: 100%;
  max-width: 400px;
  margin-bottom: 40px;
  border: 1px solid #d8f1fc;
  background-color: #f3f8fb; }
  .m-teams__label:before {
    content: "";
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(50%, -75%) rotate(45deg);
    width: 8px;
    height: 8px;
    box-sizing: border-box;
    border-right: 1px solid #2a6485;
    border-bottom: 1px solid #2a6485; }
  .m-teams__label:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 100%;
    border-left: 1px solid #d8f1fc;
    z-index: -1; }

.m-teams__select {
  border: none;
  outline: none;
  background: transparent;
  appearance: none;
  border-radius: 0;
  margin: 0;
  display: block;
  width: 100%;
  font-size: 14px;
  font-size: 0.875rem;
  padding: 10px 40px 10px 10px;
  color: #2a6485; }

.m-teams__member {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-right: -20px;
  margin-bottom: 10px;
  margin-left: -20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d8f1fc; }
  @media print, screen and (min-width: 64em) {
    .m-teams__member {
      margin-bottom: 20px;
      padding-bottom: 20px; } }
  .m-teams__member:last-child {
    margin-bottom: 0; }

.m-teams__name {
  flex: 0 0 auto;
  width: calc(41.66667% - 2.5rem);
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  font-weight: 700;
  color: #2a6485;
  line-height: 1.2em; }

.m-teams__function {
  flex: 0 0 auto;
  width: calc(58.33333% - 2.5rem);
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  line-height: 1.2em;
  color: black; }

.m-newsletter {
  /* $pad: $m-value;
	$delta: 100px;

	padding-top: $pad;
	padding-bottom: $pad + 1.3 * $delta;

	@include breakpoint(small) {
		padding-top: $pad;
		padding-bottom: $pad + $delta;
	} */
  padding-top: 40px;
  padding-bottom: 40px; }
  @media screen and (min-width: 65.625em) {
    .m-newsletter {
      display: flex;
      flex-flow: row wrap;
      padding-top: 60px;
      padding-bottom: 60px;
      align-items: center;
      /* $pad: $l-value;
		padding-top: $pad;
		padding-bottom: $pad + $delta; */ } }
  .m-newsletter__title {
    font-size: 22px;
    font-size: 1.375rem;
    color: #fff;
    font-weight: 700;
    line-height: 1.1em;
    margin-bottom: 20px; }
    @media screen and (min-width: 65.625em) {
      .m-newsletter__title {
        flex: 0 0 auto;
        width: calc(50% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        margin-bottom: 0; } }
  .m-newsletter__form {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; }
    @media screen and (min-width: 65.625em) {
      .m-newsletter__form {
        flex: 0 0 auto;
        width: calc(50% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        justify-content: flex-start; } }
    .m-newsletter__form form {
      position: relative;
      width: 100%; }
    .m-newsletter__form .form-item {
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      margin-bottom: 10px;
      /* @include breakpoint(xsmall) {
				width: 200px;
      } */ }
      @media print, screen and (min-width: 48em) {
        .m-newsletter__form .form-item {
          margin-right: 5px;
          width: 280px; } }
    .m-newsletter__form .form-item-mail label {
      display: none; }
    .m-newsletter__form label {
      font-size: 10px; }
    .m-newsletter__form input[type="email"] {
      border: none;
      max-width: 100%;
      height: 40px;
      box-sizing: border-box;
      padding: 0 10px;
      color: #2a6485;
      font-size: 14px;
      font-size: 0.875rem;
      font-family: "Open Sans", sans-serif;
      display: block;
      margin-left: auto;
      margin-right: auto; }
      @media print, screen and (min-width: 48em) {
        .m-newsletter__form input[type="email"] {
          height: 46px;
          font-size: 16px;
          font-size: 1rem;
          display: inline-block;
          padding: 0 20px; } }
    .m-newsletter__form .webform-button--submit-container {
      display: inline-block;
      position: relative;
      display: block;
      margin-top: 1rem; }
      @media print, screen and (min-width: 48em) {
        .m-newsletter__form .webform-button--submit-container {
          display: inline-block; } }
      .m-newsletter__form .webform-button--submit-container input {
        margin-left: auto !important;
        margin-right: auto !important;
        display: block; }
        @media print, screen and (min-width: 48em) {
          .m-newsletter__form .webform-button--submit-container input {
            display: inline-block; } }
      @media screen and (min-width: 80em) {
        .m-newsletter__form .webform-button--submit-container:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 20px;
          z-index: 1;
          height: 7px;
          width: 7px;
          border-top: 2px solid #fff;
          border-right: 2px solid #fff;
          box-sizing: border-box;
          transform: translate(-50%, -50%) rotate(45deg);
          transition: left 0.25s, opacity 0.25s; } }
      @media screen and (min-width: 80em) {
        .m-newsletter__form .webform-button--submit-container:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          z-index: 1;
          height: 7px;
          width: 7px;
          border-top: 2px solid #fff;
          border-right: 2px solid #fff;
          box-sizing: border-box;
          transform: translateY(-50%) rotate(45deg);
          opacity: 0;
          transition: right 0.25s, opacity 0.25s; } }
      @media screen and (min-width: 80em) {
        .m-newsletter__form .webform-button--submit-container:hover:before {
          left: 0;
          opacity: 0; } }
      @media screen and (min-width: 80em) {
        .m-newsletter__form .webform-button--submit-container:hover:after {
          right: 20px;
          opacity: 1; } }
    .m-newsletter__form input[type="submit"] {
      vertical-align: middle;
      font-family: "Open Sans", sans-serif;
      outline: none;
      margin: 0;
      appearance: none; }
      @media screen and (min-width: 80em) {
        .m-newsletter__form input[type="submit"] {
          padding-left: 40px;
          line-height: 40px;
          transition: padding 0.25s; } }
      @media screen and (min-width: 80em) {
        .m-newsletter__form input[type="submit"]:hover {
          padding-right: 40px;
          padding-left: 20px; } }
    .m-newsletter__form #edit-legal--2 {
      /* position: absolute;
            bottom: -10px; left: 0;
            width: 100%;
            transform: translateY(100%);
            max-height: 40px;
            overflow: auto; */
      width: 100%;
      margin-top: 1rem;
      /* position: absolute;
      top: 0; left: 0;
      max-height: $delta;
      transform: translateY($delta/2.5); */ }
      .m-newsletter__form #edit-legal--2 p {
        font-size: 11px;
        font-size: 0.6875rem;
        color: #fff; }

@media print, screen and (min-width: 64em) {
  .m-management {
    display: flex;
    flex-flow: row wrap; } }

.m-management__left {
  margin-bottom: 40px; }
  @media print, screen and (min-width: 64em) {
    .m-management__left {
      flex: 0 0 auto;
      width: calc(33.33333% - 2.5rem);
      margin-left: 1.25rem;
      margin-right: 1.25rem; } }

@media print, screen and (min-width: 64em) {
  .m-management__right {
    flex: 0 0 auto;
    width: calc(58.33333% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-left: calc(8.33333% + 2.5rem / 2); } }

@media print, screen and (min-width: 64em) {
  .m-management__items {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: -40px; } }

.m-management__item {
  flex: 0 0 auto;
  width: calc(100% - 2.5rem);
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  margin-bottom: 40px;
  line-height: 1.5em;
  text-align: center; }
  .m-management__item:last-child {
    margin-bottom: 0; }
    @media print, screen and (min-width: 64em) {
      .m-management__item:last-child {
        margin-bottom: 40px; } }
  @media print, screen and (min-width: 48em) {
    .m-management__item {
      flex: 0 0 auto;
      width: calc(33.33333% - 2.5rem);
      margin-left: 1.25rem;
      margin-right: 1.25rem; } }

.m-management__image {
  overflow: hidden;
  display: block;
  border-radius: 50%;
  margin: 0 auto 14px;
  position: relative;
  background-color: #2a6485;
  max-width: 170px; }
  @media screen and (min-width: 80em) {
    .m-management__image:hover img {
      opacity: 0.2; } }
  @media screen and (min-width: 80em) {
    .m-management__image:hover svg {
      opacity: 1; } }
  .m-management__image img {
    display: block;
    pointer-events: none; }
    @media screen and (min-width: 80em) {
      .m-management__image img {
        transition: opacity 0.15s; } }
  .m-management__image svg {
    position: absolute;
    top: 50%;
    left: 50%;
    fill: white;
    width: 40px;
    transform: translate(-50%, -50%);
    opacity: 0;
    z-index: 1; }
    @media screen and (min-width: 80em) {
      .m-management__image svg {
        transition: opacity 0.15s; } }

.m-management__name {
  font-size: 15px;
  font-size: 0.9375rem;
  color: #2a6485; }
  @media print, screen and (min-width: 64em) {
    .m-management__name {
      font-size: 17px;
      font-size: 1.0625rem; } }

.m-management__function {
  font-size: 12px;
  font-size: 0.75rem;
  color: #7fbad4; }
  @media print, screen and (min-width: 64em) {
    .m-management__function {
      font-size: 14px;
      font-size: 0.875rem; } }

.m-article {
  display: flex;
  flex-flow: row wrap;
  margin-right: -20px;
  margin-bottom: -40px;
  margin-left: -20px; }
  .m-article__top {
    flex-direction: column; }
  .m-article__left {
    margin-bottom: 40px;
    flex: 0 0 auto;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
    @media print, screen and (min-width: 64em) {
      .m-article__left {
        margin-bottom: 0;
        flex: 0 0 auto;
        width: calc(50% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem; } }
  .m-article__right {
    flex: 0 0 auto;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
    @media print, screen and (min-width: 64em) {
      .m-article__right {
        flex: 0 0 auto;
        width: calc(50% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem; } }
  .m-article__background-image {
    width: 100%; }
    .m-article__background-image img {
      width: 100%; }
    @media print, screen and (min-width: 48em) {
      .m-article__background-image {
        margin-right: 20px;
        margin-left: 5px;
        margin-top: -60px; } }
  .m-article__clock {
    font-size: 14px;
    font-size: 0.875rem; }
    @media print, screen and (min-width: 64em) {
      .m-article__clock {
        font-size: 16px;
        font-size: 1rem; } }
    .m-article__clock svg {
      width: 20px;
      height: 20.77px;
      vertical-align: top;
      margin-right: 5px; }
  .m-article__tag {
    font-size: 16px;
    font-size: 1rem;
    margin-bottom: 20px;
    color: #7fbad4; }
  .m-article__author-image {
    width: 50px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    overflow: hidden;
    border-radius: 50%; }
    @media screen and (min-width: 80em) {
      .m-article__author-image:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(42, 100, 133, 0);
        transition: background-color 0.15s; } }
    @media screen and (min-width: 80em) {
      .m-article__author-image:hover:after {
        background: rgba(42, 100, 133, 0.5); } }
    @media screen and (min-width: 80em) {
      .m-article__author-image:hover svg {
        opacity: 1; } }
    .m-article__author-image img {
      display: block;
      width: 50px;
      height: 50px; }
    .m-article__author-image svg {
      display: none; }
      @media screen and (min-width: 80em) {
        .m-article__author-image svg {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
          fill: #fff;
          width: 35px;
          opacity: 0;
          transition: opacity 0.15s; } }
  .m-article__author-info {
    margin-top: 10px; }
    @media print, screen and (min-width: 64em) {
      .m-article__author-info {
        margin-top: 0; } }
    @media screen and (min-width: 80em) {
      .m-article__author-info {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px; } }
    .m-article__author-info .a-text--slim {
      max-width: 250px; }

.m-view-expertise {
  display: flex;
  flex-flow: row wrap;
  margin-right: -20px;
  margin-left: -20px; }
  .m-view-expertise__row {
    flex: 0 0 auto;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-bottom: 40px;
    background-color: #fff;
    position: relative; }
    @media print, screen and (min-width: 48em) {
      .m-view-expertise__row {
        flex: 0 0 auto;
        width: calc(50% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem; }
        .m-view-expertise__row:nth-child(even) {
          transform: translateY(40px); } }
  .m-view-expertise__content {
    padding: 25px; }
  .m-view-expertise__link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

.m-view-news {
  display: flex;
  flex-flow: row wrap;
  margin-right: -20px;
  margin-bottom: -40px;
  margin-left: -20px;
  /* &__title{
      color: $tertiary;
      font-size: 2rem;
    } */ }
  .m-view-news--one-line {
    margin-bottom: 0; }
  .m-view-news__row {
    flex: 0 0 auto;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-bottom: 40px;
    border: 1px solid rgba(127, 186, 212, 0.5);
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    order: 4;
    /* &:first-child {
      margin-top: $margin;
    } */ }
    @media print, screen and (min-width: 48em) {
      .m-view-news__row {
        flex: 0 0 auto;
        width: calc(50% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem; } }
    @media print, screen and (min-width: 64em) {
      .m-view-news__row {
        flex: 0 0 auto;
        width: calc(33.33333% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem; } }
    .m-view-news__row--newsletter {
      background: #d8f1fc;
      border: none;
      min-height: 360px; }
      .m-view-news__row--newsletter .a-heading {
        margin-bottom: 20px; }
      .m-view-news__row--newsletter .webform-submission-form {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column; }
        .m-view-news__row--newsletter .webform-submission-form .js-form-item {
          max-width: 100%; }
        .m-view-news__row--newsletter .webform-submission-form #edit-legal--2 {
          margin-top: 1rem; }
      .m-view-news__row--newsletter .form-item-rgpd,
      .m-view-news__row--newsletter .form-item-newsletter-marketing,
      .m-view-news__row--newsletter .form-item-marketing {
        margin-bottom: 20px; }
        .m-view-news__row--newsletter .form-item-rgpd label,
        .m-view-news__row--newsletter .form-item-newsletter-marketing label,
        .m-view-news__row--newsletter .form-item-marketing label {
          font-size: 12px;
          line-height: 1.2em; }
      .m-view-news__row--newsletter .webform-button--submit-container::before {
        transform: rotate(45deg) translate(-50%, 50%); }
      .m-view-news__row--newsletter .webform-button--submit-container::after {
        transform: translate(-50%, 25%) rotate(45deg); }
  .m-view-news__link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .m-view-news__image img {
    width: 100%;
    display: inline-block;
    margin-bottom: -3px; }
  .m-view-news__content {
    color: #9bb1c8;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    box-sizing: border-box; }
    @media screen and (min-width: 80em) {
      .m-view-news__content {
        padding: 40px 30px; } }
  .m-view-news .views-exposed-form {
    display: none; }
  .m-view-news__newsletter input[type="email"] {
    border: none;
    max-width: 100%;
    height: 40px;
    box-sizing: border-box;
    padding: 0 10px;
    color: #2a6485;
    font-size: 14px;
    font-size: 0.875rem;
    font-family: "Open Sans", sans-serif; }
    @media print, screen and (min-width: 48em) {
      .m-view-news__newsletter input[type="email"] {
        height: 46px;
        font-size: 16px;
        font-size: 1rem;
        padding: 0 20px; } }
  .m-view-news__newsletter input[type="submit"] {
    margin-top: 10px;
    font-family: "Open Sans", sans-serif;
    outline: none;
    appearance: none; }
  .m-view-news__newsletter #edit-legal {
    font-size: 11px;
    font-size: 0.6875rem;
    margin: 5px 0; }
  .m-view-news__rss {
    display: inline-block;
    background: #2a6485;
    height: 46px;
    line-height: 46px;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
    color: white;
    font-size: 14px;
    font-size: 0.875rem;
    position: relative; }
    @media print, screen and (min-width: 48em) {
      .m-view-news__rss {
        font-size: 16px;
        font-size: 1rem; } }
    .m-view-news__rss svg {
      width: 21px;
      height: 21px;
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%); }
  .m-view-news .pager {
    order: 5;
    width: 100%;
    text-align: center; }
    .m-view-news .pager .pager__item {
      display: inline-block; }
      .m-view-news .pager .pager__item--first, .m-view-news .pager .pager__item--last {
        display: none; }
      .m-view-news .pager .pager__item--next:after {
        display: none; }
      .m-view-news .pager .pager__item:after {
        content: "-";
        margin: 5px; }
      .m-view-news .pager .pager__item.is-active a {
        font-weight: 700;
        text-decoration: underline; }
      .m-view-news .pager .pager__item a {
        color: #2a6485;
        font-size: 16px;
        font-size: 1rem;
        text-decoration: none; }

.o-block--list .m-view-news__row:nth-child(1) {
  order: 2; }
  @media print, screen and (min-width: 64em) {
    .o-block--list .m-view-news__row:nth-child(1) {
      order: 3; } }

.o-block--list .m-view-news__row:nth-child(2) {
  order: 1; }

.o-block--list .m-view-news__row:nth-child(3) {
  order: 2; }

@media print, screen and (min-width: 48em) {
  .o-block--list .m-view-news__row:nth-child(-n+2) {
    margin-top: 40px; } }

@media print, screen and (min-width: 64em) {
  .o-block--list .m-view-news__row:nth-child(-n+3) {
    margin-top: 40px; } }

.m-testimonials__logos {
  margin-left: 0;
  margin-bottom: 40px;
  overflow: visible;
  width: 80%; }
  @media print, screen and (min-width: 64em) {
    .m-testimonials__logos {
      width: 260px;
      margin-bottom: 60px; } }

.m-testimonials__logo {
  /*width: 200px;*/
  /*margin-right: $s-value;*/
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  border: none;
  box-sizing: border-box;
  transition: border-color 0.25s; }
  @media print, screen and (min-width: 64em) {
    .m-testimonials__logo {
      /*width: 260px;*/ } }
  .m-testimonials__logo:last-child {
    margin-right: 0; }
  .m-testimonials__logo.swiper-slide-active {
    border: 2px solid #7fbad4; }
  .m-testimonials__logo img {
    display: block; }

@media print, screen and (min-width: 48em) {
  .m-testimonials__content {
    display: flex;
    flex-flow: row wrap;
    margin-right: -20px;
    margin-left: -20px; } }

@media print, screen and (min-width: 48em) {
  .m-testimonials__right {
    flex: 0 0 auto;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    padding-right: 40px;
    box-sizing: border-box; } }

.m-contact {
  display: flex;
  flex-flow: row wrap;
  margin-right: -20px;
  margin-bottom: -40px;
  margin-left: -20px; }
  .m-contact__left {
    flex: 0 0 auto;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-bottom: 40px; }
    @media print, screen and (min-width: 64em) {
      .m-contact__left {
        flex: 0 0 auto;
        width: calc(50% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem; } }
  .m-contact__right {
    width: 100%; }
    @media print, screen and (min-width: 64em) {
      .m-contact__right {
        flex: 0 0 auto;
        width: calc(41.66667% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        margin-left: calc(8.33333% + 2.5rem / 2); } }
  .m-contact__coordinate, .m-contact__social-network {
    padding: 30px; }
    @media print, screen and (min-width: 64em) {
      .m-contact__coordinate, .m-contact__social-network {
        padding: 40px;
        min-height: 357px;
        box-sizing: border-box; } }
  @media print, screen and (min-width: 64em) {
    .m-contact__coordinate {
      margin-bottom: 0; } }
  .m-contact__coordinate p {
    line-height: normal; }
  .m-contact__social-network {
    margin-bottom: 40px; }
    @media print, screen and (min-width: 64em) {
      .m-contact__social-network {
        margin-bottom: 0; } }
  .m-contact--grey {
    background-color: #f3f8fb; }
  .m-contact--tertiary {
    background-color: #7fbad4; }
  @media print, screen and (min-width: 64em) {
    .m-contact--sticky {
      position: sticky;
      top: 40px; } }

@media screen and (min-width: 80em) {
  .m-expertises {
    display: flex;
    flex-flow: row wrap;
    margin-right: -20px;
    margin-left: -20px; } }

@media screen and (min-width: 80em) {
  .m-expertises__left {
    flex: 0 0 auto;
    width: calc(16.66667% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem; } }

@media screen and (min-width: 80em) {
  .m-expertises__left .a-heading--underline  {
    text-align: left; } }

@media screen and (min-width: 80em) {
  .m-expertises__left .a-heading--centered:after {
    left: 0;
    transform: none; } }

@media print, screen and (min-width: 48em) {
  .m-expertises__right {
    display: flex;
    flex-flow: row wrap;
    margin-right: -20px;
    margin-left: -20px; } }

@media screen and (min-width: 80em) {
  .m-expertises__right {
    flex: 0 0 auto;
    width: calc(83.33333% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem; } }

.m-expertises__expertise {
  text-align: center;
  padding-bottom: 40px;
  margin-bottom: 40px;
  position: relative; }
  @media print, screen and (min-width: 48em) {
    .m-expertises__expertise {
      flex: 0 0 auto;
      width: calc(50% - 2.5rem);
      margin-left: 1.25rem;
      margin-right: 1.25rem;
      padding: 0 40px;
      box-sizing: border-box; } }
  @media print, screen and (min-width: 64em) {
    .m-expertises__expertise {
      flex: 0 0 auto;
      width: calc(25% - 2.5rem);
      margin-left: 1.25rem;
      margin-right: 1.25rem;
      padding: 0 10px;
      margin-bottom: 0; } }
  @media screen and (min-width: 80em) {
    .m-expertises__expertise {
      z-index: 1; } }
  @media screen and (min-width: 80em) {
    .m-expertises__expertise:hover:before {
      opacity: 1; } }
  @media screen and (min-width: 80em) {
    .m-expertises__expertise:hover .m-expertises__discover {
      opacity: 0; } }
  @media screen and (min-width: 80em) {
    .m-expertises__expertise:hover .m-expertises__bubble {
      opacity: 1; } }
  .m-expertises__expertise:last-child {
    padding-bottom: 0;
    margin-bottom: 0; }
    @media print, screen and (min-width: 48em) {
      .m-expertises__expertise:last-child {
        padding: 0 40px; } }
    @media print, screen and (min-width: 64em) {
      .m-expertises__expertise:last-child {
        padding: 0; } }
    .m-expertises__expertise:last-child:after {
      display: none; }
  @media screen and (min-width: 80em) {
    .m-expertises__expertise:before {
      content: '';
      position: absolute;
      top: -60px;
      right: -20px;
      bottom: -60px;
      left: -20px;
      z-index: -1;
      background: #d8f1fc;
      opacity: 0;
      transition: opacity 0.15s; } }
  .m-expertises__expertise:after {
    content: '';
    height: 1px;
    width: 60px;
    background: #d8f1fc;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); }
    @media print, screen and (min-width: 48em) {
      .m-expertises__expertise:after {
        display: none; } }
  .m-expertises__expertise img {
    display: inline-block;
    width: 70px; }

.m-expertises__text {
  font-size: 12px;
  font-size: 0.75rem;
  color: #9bb1c8;
  margin-bottom: 20px; }

.m-expertises__discover {
  display: inline-block;
  font-size: 14px;
  font-size: 0.875rem;
  color: #7fbad4;
  text-decoration: underline;
  position: relative;
  padding-left: 15px; }
  @media screen and (min-width: 80em) {
    .m-expertises__discover {
      transition: opacity 0.25s; } }
  .m-expertises__discover:before {
    content: '';
    width: 5px;
    height: 5px;
    border-top: 2px solid #7fbad4;
    border-right: 2px solid #7fbad4;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%) rotate(45deg); }

@media screen and (min-width: 80em) {
  .m-expertises__bubble {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    height: 40px;
    width: 40px;
    background-color: #fff;
    border-radius: 100%;
    opacity: 0; } }

@media screen and (min-width: 80em) {
  .m-expertises__bubble {
    transition: opacity 0.25s; } }

@media screen and (min-width: 80em) {
  .m-expertises__bubble:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    height: 7px;
    width: 7px;
    border-top: 2px solid #2a6485;
    border-right: 2px solid #2a6485;
    box-sizing: border-box;
    transform: translate(-50%, -50%) rotate(45deg); } }

@media screen and (min-width: 80em) {
  .m-expertises__link {
    position: absolute;
    top: -60px;
    right: -20px;
    bottom: -60px;
    left: -20px; } }

.m-expertise {
  display: flex;
  flex-flow: row wrap;
  margin-right: -20px;
  margin-bottom: -40px;
  margin-left: -20px; }
  .m-expertise__left {
    flex: 0 0 auto;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-bottom: 40px; }
    @media print, screen and (min-width: 64em) {
      .m-expertise__left {
        margin-bottom: 0;
        flex: 0 0 auto;
        width: calc(41.66667% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem; } }
  .m-expertise__right {
    flex: 0 0 auto;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
    @media print, screen and (min-width: 64em) {
      .m-expertise__right {
        flex: 0 0 auto;
        width: calc(50% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        margin-left: calc(8.33333% + 2.5rem / 2); } }
  @media print, screen and (min-width: 64em) {
    .m-expertise__sticky {
      position: sticky;
      top: 90px; } }

@media screen and (min-width: 80em) {
  .m-application {
    display: flex;
    flex-flow: row wrap; } }

.m-application__left {
  margin-bottom: 40px; }
  @media print, screen and (min-width: 64em) {
    .m-application__left {
      flex: 0 0 auto;
      width: calc(41.66667% - 2.5rem);
      margin-left: 1.25rem;
      margin-right: 1.25rem; } }

@media print, screen and (min-width: 64em) {
  .m-application__right {
    flex: 0 0 auto;
    width: calc(50% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-left: calc(8.33333% + 2.5rem / 2); } }

.m-event {
  margin-right: -20px;
  margin-left: -20px; }
  @media print, screen and (min-width: 64em) {
    .m-event {
      display: flex;
      flex-flow: row wrap; } }
  .m-event__left {
    background-color: #fd7373;
    position: relative; }
    @media print, screen and (min-width: 64em) {
      .m-event__left {
        flex: 0 0 auto;
        width: calc(33.33333% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        margin-bottom: 0px;
        background-color: transparent; } }
    @media print, screen and (min-width: 64em) {
      .m-event__left:after {
        content: '';
        position: absolute;
        background-color: #fd7373;
        top: -40px;
        bottom: -40px;
        z-index: -1;
        left: 0;
        right: -120px;
        border-left: 1px solid rgba(255, 255, 255, 0.5); } }
  @media print, screen and (min-width: 64em) {
    .m-event__right {
      flex: 0 0 auto;
      width: calc(66.66667% - 2.5rem);
      margin-left: 1.25rem;
      margin-right: 1.25rem; } }
  .m-event__content {
    padding: 40px 20px;
    box-sizing: border-box; }
    @media print, screen and (min-width: 64em) {
      .m-event__content {
        padding: 80px 0 80px 40px; } }
    @media print, screen and (min-width: 64em) {
      .m-event__content:after {
        content: "";
        border-right: 1px solid rgba(255, 255, 255, 0.5);
        position: absolute;
        top: -80px;
        bottom: -80px;
        right: -40px; } }
    @media print, screen and (min-width: 64em) {
      .m-event__content:before {
        content: "";
        border-top: 1px solid rgba(255, 255, 255, 0.5);
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        position: absolute;
        top: 0;
        bottom: 0;
        right: -40px;
        left: 0; } }
  .m-event__item {
    position: relative;
    padding: 40px 20px;
    box-sizing: border-box; }
    @media print, screen and (min-width: 64em) {
      .m-event__item {
        height: 100%;
        padding-left: 40px; } }
    .m-event__item img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      width: 100%;
      object-fit: cover; }
    .m-event__item a {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 2; }
    .m-event__item:after {
      content: '';
      position: absolute;
      background-image: linear-gradient(to right, rgba(42, 100, 133, 0.9), rgba(42, 100, 133, 0.6));
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
      @media print, screen and (min-width: 64em) {
        .m-event__item:after {
          background-image: linear-gradient(to right, #2a6485 25%, rgba(42, 100, 133, 0.6)); } }
  .m-event__text {
    position: relative;
    z-index: 1; }
    @media print, screen and (min-width: 64em) {
      .m-event__text {
        position: absolute;
        top: 50%;
        left: 40px;
        transform: translateY(-50%);
        max-width: 400px; } }
    .m-event__text .a-text {
      margin-top: 20px; }

.m-iam {
  display: flex;
  flex-flow: row wrap;
  margin-right: -20px;
  margin-left: -20px; }
  .m-iam__left {
    flex: 0 0 auto;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-bottom: 40px; }
    @media print, screen and (min-width: 64em) {
      .m-iam__left {
        margin-bottom: 0;
        flex: 0 0 auto;
        width: calc(41.66667% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem; } }
  .m-iam__right {
    flex: 0 0 auto;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
    @media print, screen and (min-width: 64em) {
      .m-iam__right {
        flex: 0 0 auto;
        width: calc(50% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        margin-left: calc(8.33333% + 2.5rem / 2); } }
    .m-iam__right > *:first-child {
      margin-top: 0; }
  @media print, screen and (min-width: 64em) {
    .m-iam__sticky {
      position: sticky;
      top: 90px; } }

@media print, screen and (min-width: 64em) {
  .m-offers {
    display: flex;
    flex-flow: row wrap;
    margin-right: -20px;
    margin-bottom: -40px;
    margin-left: -20px; } }

.m-offers__left {
  margin-bottom: 40px; }
  @media print, screen and (min-width: 64em) {
    .m-offers__left {
      margin-bottom: 0;
      flex: 0 0 auto;
      width: calc(41.66667% - 2.5rem);
      margin-left: 1.25rem;
      margin-right: 1.25rem; } }
  .m-offers__left .a-text ul {
    margin-left: 0; }
  .m-offers__left .a-text p {
    margin-left: 30px; }

@media print, screen and (min-width: 64em) {
  .m-offers__right {
    flex: 0 0 auto;
    width: calc(50% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-left: calc(8.33333% + 2.5rem / 2);
    position: relative; } }

.m-offers__introduction {
  margin-bottom: 40px; }

@media print, screen and (min-width: 64em) {
  .m-offers__lift {
    position: sticky;
    top: 90px; } }

@media print, screen and (min-width: 64em) {
  .m-commercial {
    display: flex;
    flex-flow: row wrap; } }

.m-commercial__left {
  margin-bottom: 40px; }
  @media print, screen and (min-width: 64em) {
    .m-commercial__left {
      margin-bottom: 0;
      flex: 0 0 auto;
      width: calc(33.33333% - 2.5rem);
      margin-left: 1.25rem;
      margin-right: 1.25rem;
      margin-left: calc(8.33333% + 2.5rem / 2); } }
  .m-commercial__left img {
    border-radius: 50%; }

@media print, screen and (min-width: 64em) {
  .m-commercial__right {
    position: relative;
    flex: 0 0 auto;
    width: calc(58.33333% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem; } }

.m-list-job {
  display: flex;
  flex-flow: row wrap;
  margin-right: -20px;
  margin-bottom: -20px;
  margin-left: -20px; }
  .m-list-job__left {
    flex: 0 0 auto;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
    @media print, screen and (min-width: 64em) {
      .m-list-job__left {
        flex: 0 0 auto;
        width: calc(33.33333% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem; } }
    @media screen and (min-width: 80em) {
      .m-list-job__left {
        flex: 0 0 auto;
        width: calc(25% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem; } }
    .m-list-job__left .a-btn--border {
      display: none; }
      @media print, screen and (min-width: 64em) {
        .m-list-job__left .a-btn--border {
          display: inline-block; } }
  .m-list-job__right {
    flex: 0 0 auto;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
    @media print, screen and (min-width: 64em) {
      .m-list-job__right {
        flex: 0 0 auto;
        width: calc(66.66667% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem; } }
    @media screen and (min-width: 80em) {
      .m-list-job__right {
        margin-left: calc(8.33333% + 2.5rem / 2); } }
  .m-list-job__tags {
    margin-bottom: 40px; }
  .m-list-job__tag {
    display: block;
    padding-bottom: 10px;
    margin-bottom: 15px;
    border-bottom: 1px solid #d8f1fc;
    color: #7fbad4;
    font-weight: 700;
    cursor: pointer;
    transition: color 0.15s; }
    .m-list-job__tag--active {
      color: #2a6485; }
  .m-list-job__job {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 20px;
    position: relative;
    /* @include breakpoint(xlarge) {
            border-right: 1px solid $tertiary;
        } */ }
    @media screen and (min-width: 80em) {
      .m-list-job__job:hover .m-list-job__job-right {
        background-color: #7fbad4; } }
    @media screen and (min-width: 80em) {
      .m-list-job__job:hover .m-list-job__item {
        color: #fff; }
        .m-list-job__job:hover .m-list-job__item svg {
          stroke: #fff;
          fill: #fff; } }
    @media screen and (min-width: 80em) {
      .m-list-job__job:hover .m-list-job__bubble {
        opacity: 1; } }
    .m-list-job__job a {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0; }
  .m-list-job__job-left {
    width: 30%;
    position: relative;
    background: #d8f1fc; }
    @media print, screen and (min-width: 64em) {
      .m-list-job__job-left {
        width: 20%; } }
  .m-list-job__job-right {
    background: #f3f8fb;
    width: 70%;
    padding: 20px;
    box-sizing: border-box;
    position: relative; }
    @media print, screen and (min-width: 64em) {
      .m-list-job__job-right {
        padding-left: 40px;
        padding-right: 40px;
        width: 80%; } }
    @media screen and (min-width: 80em) {
      .m-list-job__job-right {
        transition: background-color 0.25s; } }
  .m-list-job__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .m-list-job__svg {
    width: 80px;
    height: 31.8px; }
  @media print, screen and (min-width: 64em) {
    .m-list-job__content {
      display: flex; } }
  .m-list-job__item {
    display: flex;
    margin-bottom: 10px;
    font-size: 14px;
    font-size: 0.875rem; }
    @media print, screen and (min-width: 64em) {
      .m-list-job__item {
        padding-right: 20px;
        margin-bottom: 0; } }
    .m-list-job__item:last-child {
      margin-bottom: 0; }
    .m-list-job__item svg {
      width: 16px;
      height: 16px;
      margin-top: -1px;
      margin-right: 5px;
      fill: #7fbad4;
      stroke: #7fbad4; }
  @media print, screen and (min-width: 64em) {
    .m-list-job__bubble {
      position: absolute;
      top: 50%;
      right: 0;
      height: 40px;
      width: 40px;
      background-color: #fff;
      border-radius: 100%;
      transform: translate(-50%, -50%);
      opacity: 0; } }
  @media screen and (min-width: 80em) {
    .m-list-job__bubble {
      transition: opacity 0.25s; } }
  @media print, screen and (min-width: 64em) {
    .m-list-job__bubble:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      height: 7px;
      width: 7px;
      border-top: 2px solid #7fbad4;
      border-right: 2px solid #7fbad4;
      box-sizing: border-box;
      transform: translate(-50%, -50%) rotate(45deg); } }
  .m-list-job .views-exposed-form {
    display: none; }
  .m-list-job .pager {
    width: 100%;
    text-align: center; }
    .m-list-job .pager .pager__item {
      display: inline-block; }
      .m-list-job .pager .pager__item--first, .m-list-job .pager .pager__item--last {
        display: none; }
      .m-list-job .pager .pager__item--next:after {
        display: none; }
      .m-list-job .pager .pager__item:after {
        content: '-';
        margin: 5px; }
      .m-list-job .pager .pager__item.is-active a {
        font-weight: 700; }
      .m-list-job .pager .pager__item a {
        color: #2a6485;
        font-size: 16px;
        font-size: 1rem;
        text-decoration: none; }

.m-related {
  margin-bottom: -40px; }
  .m-related__titles {
    border-bottom: 1px solid #7fbad4;
    margin-bottom: 40px;
    white-space: nowrap;
    overflow: auto; }
  .m-related__title {
    display: inline-block;
    font-family: 'Muli', sans-serif;
    line-height: 1.3em;
    font-weight: 700;
    color: #7fbad4;
    font-size: 26px;
    font-size: 1.625rem;
    padding-bottom: 20px;
    position: relative;
    margin-right: 40px;
    cursor: pointer; }
    .m-related__title:last-child {
      margin-right: 0; }
    .m-related__title--active {
      color: #fd7373; }
      .m-related__title--active:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 10px;
        background: #fd7373; }

.m-publication {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  margin-right: -20px;
  margin-left: -20px; }
  @media print, screen and (min-width: 64em) {
    .m-publication {
      align-items: stretch;
      margin-bottom: -40px; } }
  .m-publication__top {
    flex-direction: column; }
  .m-publication__left {
    margin-bottom: 40px;
    flex: 0 0 auto;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
    @media print, screen and (min-width: 64em) {
      .m-publication__left {
        margin-bottom: 0;
        flex: 0 0 auto;
        width: calc(50% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem; } }
  .m-publication__right {
    width: 100%; }
    @media print, screen and (min-width: 64em) {
      .m-publication__right {
        flex: 0 0 auto;
        width: calc(50% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        position: relative; } }
  .m-publication__elevator {
    background-color: #d8f1fc;
    box-sizing: border-box;
    padding: 20px; }
    @media print, screen and (min-width: 64em) {
      .m-publication__elevator {
        padding: 40px;
        position: sticky;
        top: 70px; } }
  .m-publication__background-image {
    width: 100%; }
    .m-publication__background-image img {
      width: 100%; }
    @media print, screen and (min-width: 48em) {
      .m-publication__background-image {
        margin: -60px 20px -30px 5px; } }
  .m-publication__tag {
    font-size: 16px;
    font-size: 1rem;
    margin-bottom: 20px;
    color: #7fbad4; }

.m-webinar {
  display: flex;
  flex-flow: row wrap; }
  .m-webinar__left {
    margin-bottom: 40px; }
    @media print, screen and (min-width: 64em) {
      .m-webinar__left {
        flex: 0 0 auto;
        width: calc(50% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem; } }
  @media print, screen and (min-width: 64em) {
    .m-webinar__right {
      flex: 0 0 auto;
      width: calc(50% - 2.5rem);
      margin-left: 1.25rem;
      margin-right: 1.25rem; } }
  @media screen and (min-width: 80em) {
    .m-webinar__right {
      flex: 0 0 auto;
      width: calc(41.66667% - 2.5rem);
      margin-left: 1.25rem;
      margin-right: 1.25rem;
      margin-left: calc(8.33333% + 2.5rem / 2); } }

.m-problematics {
  display: flex;
  flex-flow: row wrap;
  margin-right: -20px;
  margin-left: -20px; }
  @media print, screen and (min-width: 64em) {
    .m-problematics {
      align-items: stretch; } }
  .m-problematics__left {
    width: 100%; }
    @media print, screen and (min-width: 64em) {
      .m-problematics__left {
        flex: 0 0 auto;
        width: calc(41.66667% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem; } }
  .m-problematics__right {
    width: 100%; }
    @media print, screen and (min-width: 64em) {
      .m-problematics__right {
        flex: 0 0 auto;
        width: calc(58.33333% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        position: relative; } }
  .m-problematics__title {
    padding: 20px;
    border-bottom: 1px solid #d8f1fc;
    font-weight: 700;
    position: relative; }
    @media screen and (min-width: 80em) {
      .m-problematics__title:hover {
        cursor: pointer;
        background: #d8f1fc; } }
    .m-problematics__title--active {
      background: #fd7373 !important;
      color: #fff; }
      @media screen and (min-width: 80em) {
        .m-problematics__title--active:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
          width: 20px;
          background: #fd7373;
          transform: translateX(100%); } }
      .m-problematics__title--active:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 5px;
        /* $grid-margin-gutters / 2 */
        transform: translateY(-50%) rotate(45deg);
        width: 8px;
        height: 8px;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        box-sizing: border-box; }
  .m-problematics__sticky {
    background: #fff;
    padding: 40px 20px;
    box-sizing: border-box; }
    @media screen and (min-width: 80em) {
      .m-problematics__sticky:before {
        content: '';
        position: absolute;
        top: 0;
        right: -40px;
        bottom: 0;
        left: -40px;
        background: #fff; } }

.m-accordion {
  border: 1px solid #d8f1fc;
  margin-bottom: 10px;
  overflow: hidden; }
  @media print, screen and (min-width: 48em) {
    .m-accordion {
      margin-bottom: 20px; } }
  @media print, screen and (min-width: 64em) {
    .m-accordion {
      background: #fff; } }
  .m-accordion__question {
    position: relative;
    padding: 20px 10px;
    box-sizing: border-box;
    cursor: pointer; }
    @media print, screen and (min-width: 48em) {
      .m-accordion__question {
        padding: 20px; } }
    @media screen and (min-width: 80em) {
      .m-accordion__question:hover {
        background-image: linear-gradient(to bottom, #f3f8fb, #fff); } }
    .m-accordion__question.accordino__question--opened:after {
      opacity: 0; }
    .m-accordion__question:before, .m-accordion__question:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 10px;
      height: 2px;
      width: 15px;
      background: #2a6485;
      transform: translateY(-50%);
      transform-origin: center; }
      @media print, screen and (min-width: 48em) {
        .m-accordion__question:before, .m-accordion__question:after {
          right: 20px; } }
    .m-accordion__question:after {
      transform: translateY(-50%) rotate(90deg);
      transition: opacity 0.25s; }
    .m-accordion__question .a-heading {
      line-height: 1em; }
  .m-accordion__answer {
    display: none;
    font-size: 12px; }
    @media print, screen and (min-width: 48em) {
      .m-accordion__answer {
        font-size: 14px; } }
  .m-accordion__content {
    padding-right: 10px;
    padding-bottom: 20px;
    padding-left: 10px;
    box-sizing: border-box; }
    @media print, screen and (min-width: 48em) {
      .m-accordion__content {
        padding-right: 20px;
        padding-left: 20px; } }
  .m-accordion__files {
    margin-top: 20px; }
    .m-accordion__files a {
      display: list-item;
      list-style-type: none;
      color: #2a6485;
      margin-top: 5px; }

@media print, screen and (min-width: 64em) {
  .m-photos__photo {
    width: 650px; } }

.m-photos__photo img {
  display: block;
  width: auto; }

.m-photos .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  border-radius: 0%;
  background: #fff;
  opacity: 1; }
  .m-photos .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #fd7373; }

.m-recruitment {
  display: flex;
  flex-flow: row wrap;
  margin-right: -20px;
  margin-bottom: -40px;
  margin-left: -20px; }
  .m-recruitment__left {
    margin-bottom: 40px;
    flex: 0 0 auto;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
    @media print, screen and (min-width: 64em) {
      .m-recruitment__left {
        margin-bottom: 0;
        flex: 0 0 auto;
        width: calc(33.33333% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem; } }
  .m-recruitment__right {
    flex: 0 0 auto;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
    @media print, screen and (min-width: 64em) {
      .m-recruitment__right {
        flex: 0 0 auto;
        width: calc(66.66667% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem; } }
  .m-recruitment__item {
    padding-right: 10px; }
  .m-recruitment__encart {
    background-color: #fd7373;
    padding: 30px; }
  .m-recruitment__date {
    display: block;
    padding: 20px;
    text-align: center;
    border-bottom: 1px solid #fd7373; }

/*
<table class="m-table">
  <thead class="m-table__head">
    <tr class="m-table__row m-table__row--head">
      <th class="m-table__cell m-table__cell--head"></th>
      <!-- [...] -->
      <th class="m-table__cell m-table__cell--head"></th>
    </tr>
  </thead>
  <tbody class="m-table__body">
    <tr class="m-table__row m-table__row--body">
      <th class="m-table__cell m-table__cell--body"></th>
      <!-- [...] -->
      <th class="m-table__cell m-table__cell--body"></th>
    </tr>
    <!-- [...] -->
    <tr class="m-table__row m-table__row--body">
      <th class="m-table__cell m-table__cell--body"></th>
      <!-- [...] -->
      <th class="m-table__cell m-table__cell--body"></th>
    </tr>
  </tbody>
</table>
*/
.m-table {
  width: 100%;
  border: 1px solid currentColor;
  /* th, td{
    border: solid currentColor;
    padding: 1em;
  }

  th{
    border-width: 2px;
  }

  td{
    border-width: 1px;
  } */ }
  .m-table__cell {
    border: 1px solid currentColor;
    padding: 1em; }
    .m-table__cell--head {
      border-bottom-width: 2px;
      font-weight: bold; }

.m-slide {
  text-align: center; }
  @media print, screen and (min-width: 64em) {
    .m-slide:before, .m-slide:after {
      content: '';
      width: 47px;
      height: 47px;
      position: absolute;
      border-radius: 50%; } }
  @media print, screen and (min-width: 64em) {
    .m-slide:before {
      bottom: 0;
      left: 10%;
      background: #7fbad4; } }
  @media print, screen and (min-width: 64em) {
    .m-slide:after {
      bottom: 5%;
      right: 10%;
      background: #2a6485; } }
  .m-slide__bubble {
    display: none; }
    @media print, screen and (min-width: 64em) {
      .m-slide__bubble {
        display: block;
        content: '';
        width: 47px;
        height: 47px;
        position: absolute;
        border-radius: 50%;
        top: 5%;
        right: 5%;
        background: #7fbad4; } }
  .m-slide .swiper-slide {
    padding-bottom: 60px; }
    @media print, screen and (min-width: 64em) {
      .m-slide .swiper-slide {
        padding: 0 120px;
        padding-bottom: 40px;
        box-sizing: border-box; } }
  .m-slide__logo {
    width: 260px;
    margin: auto; }
  .m-slide .a-text {
    max-width: 480px;
    margin: auto; }
  .m-slide__discover a {
    display: inline-block;
    font-size: 14px;
    font-size: 0.875rem;
    color: #7fbad4;
    text-decoration: underline;
    position: relative;
    padding-left: 15px;
    margin-top: 20px; }
    @media screen and (min-width: 80em) {
      .m-slide__discover a {
        transition: color 0.15s; } }
    .m-slide__discover a:before {
      content: '';
      width: 5px;
      height: 5px;
      border-top: 2px solid #7fbad4;
      border-right: 2px solid #7fbad4;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%) rotate(45deg); }
      @media screen and (min-width: 80em) {
        .m-slide__discover a:before {
          transition: border-color 0.15s; } }
    .m-slide__discover a:after {
      display: none; }
    @media screen and (min-width: 80em) {
      .m-slide__discover a:hover {
        color: #2a6485; } }
    @media screen and (min-width: 80em) {
      .m-slide__discover a:hover:before {
        border-top: 2px solid #2a6485;
        border-right: 2px solid #2a6485; } }
  .m-slide .swiper-button-prev, .m-slide .swiper-button-next {
    display: none; }
    @media print, screen and (min-width: 64em) {
      .m-slide .swiper-button-prev, .m-slide .swiper-button-next {
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        height: 40px;
        width: 40px;
        background-color: #fff;
        border-radius: 100%;
        border: 1px solid #7fbad4;
        box-sizing: border-box; } }
    @media print, screen and (min-width: 64em) {
      .m-slide .swiper-button-prev:after, .m-slide .swiper-button-next:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        height: 7px;
        width: 7px;
        border-top: 2px solid #7fbad4;
        border-right: 2px solid #7fbad4;
        box-sizing: border-box;
        transform: translate(-50%, -50%) rotate(-135deg); } }
  @media print, screen and (min-width: 64em) {
    .m-slide .swiper-button-next {
      right: 0;
      left: auto; } }
  @media print, screen and (min-width: 64em) {
    .m-slide .swiper-button-next:after {
      transform: translate(-50%, -50%) rotate(45deg); } }
  .m-slide .swiper-pagination-bullets {
    bottom: 0; }
  .m-slide .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    margin: 0 8px !important;
    outline: none; }
  .m-slide .swiper-pagination-bullet-active {
    background: #7fbad4; }

.m-lang {
  position: relative;
  display: inline-block;
  width: 40px;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: bold;
  text-align: left; }
  .m-lang--open .m-lang__title::after {
    transform: translateY(-25%) rotate(225deg); }
  .m-lang--open .m-lang__items {
    opacity: 1; }
  @media print, screen and (min-width: 64em) {
    .m-lang:hover .m-lang__title::after {
      transform: translateY(-25%) rotate(225deg); }
    .m-lang:hover .m-lang__items {
      opacity: 1; } }
  .m-lang__title {
    display: block;
    width: 100%;
    color: #7fbad4; }
    .m-lang__title::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      width: 6px;
      height: 6px;
      transform: translateY(-75%) rotate(45deg);
      transition: transform 0.25s;
      background-color: transparent;
      border-right: 2px solid currentColor;
      border-bottom: 2px solid currentColor; }
  .m-lang__items {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 60px;
    min-width: 100%;
    transform: translateY(1em);
    transition: opacity 0.15s;
    z-index: 1;
    opacity: 0;
    background-color: #fefefe;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1); }
  .m-lang__item {
    position: relative;
    color: #2a6485;
    text-align: center;
    text-decoration: underline; }
    @media print, screen and (min-width: 64em) {
      .m-lang__item {
        text-decoration: none;
        transition: color 0.25s; } }
    @media print, screen and (min-width: 64em) {
      .m-lang__item:hover {
        color: #7fbad4; } }
    .m-lang__item.is-active {
      opacity: 0.3;
      cursor: not-allowed;
      pointer-events: none; }
    .m-lang__item + .m-lang__item {
      border-top: 1px solid #d8f1fc; }
    .m-lang__item > :first-child {
      display: block;
      padding: 10px 5px; }
  .m-lang a {
    text-decoration: none;
    color: inherit; }

.o-body--home.o-body--static .m-lang__title {
  color: #fefefe; }

.o-body {
  /*
		Allows:
			browser(ie)
			browser("ie.mobile")
			browser("firefox.android")
			browser(ie, "firefox.ios")
			etc...
		*/ }
  html.ios .o-body {
    overscroll-behavior-y: none; }
  .o-body--menu-on {
    overflow: hidden; }

.o-header {
  height: 70px;
  width: 100%;
  background: #fff;
  position: relative; }
  @media print, screen and (min-width: 64em) {
    .o-header {
      position: fixed;
      top: 0;
      z-index: 2;
      transition: transform 0.25s, background-color 0.25s, border-color 0.25s; } }
  @media screen and (min-width: 80em) {
    .o-header {
      background: transparent;
      border-color: transparent; } }
  @media print, screen and (min-width: 64em) {
    .o-header:before {
      content: '';
      position: absolute;
      top: 0;
      right: 50%;
      bottom: 0;
      left: 0;
      background: #fff;
      border-bottom: 1px solid #7fbad4;
      transition: background-color 0.25s, border-color 0.25s; } }
  .o-header__container {
    display: flex;
    flex-flow: row wrap;
    height: 100%;
    align-items: center; }
    @media screen and (min-width: 80em) {
      .o-header__container {
        position: relative; } }
    @media print, screen and (min-width: 64em) {
      .o-header__container:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 50%;
        z-index: -1;
        background: #fff;
        border-bottom: 1px solid #7fbad4;
        transition: background-color 0.25s, border-color 0.25s; } }
    @media screen and (min-width: 80em) {
      .o-header__container:before {
        right: 20px; } }
    @media screen and (min-width: 80em) {
      .o-header__container:after {
        content: '';
        position: absolute;
        top: 0;
        right: 20px;
        bottom: 0;
        width: 1px;
        background: #7fbad4; } }
  .o-header__left {
    flex: 0 0 auto;
    width: calc(50% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
    @media print, screen and (min-width: 64em) {
      .o-header__left {
        flex: 0 0 auto;
        width: calc(15% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem; } }
  .o-header__middle {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    background: #2a6485;
    overflow: auto;
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 0.15s, transform 0s 0.15s; }
    @media print, screen and (min-width: 64em) {
      .o-header__middle {
        flex: 0 0 auto;
        width: calc(75% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        height: 70px;
        position: static;
        transform: none;
        opacity: 1;
        background: transparent;
        overflow: visible; } }
  .o-header__right {
    flex: 0 0 auto;
    width: calc(50% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    text-align: right; }
    @media print, screen and (min-width: 64em) {
      .o-header__right {
        flex: 0 0 auto;
        width: calc(10% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        padding-right: 20px;
        box-sizing: border-box; } }
  .o-header__svg {
    width: 80px;
    height: 31.8px; }
    @media print, screen and (min-width: 64em) {
      .o-header__svg {
        width: 99px;
        height: 40px; } }
    .o-header__svg--white {
      display: none; }
  .o-header__logo {
    position: relative;
    z-index: 1; }
  .o-header__logo-mobile {
    display: block;
    width: 100px;
    margin: 20px auto; }
    @media print, screen and (min-width: 64em) {
      .o-header__logo-mobile {
        display: none; } }

.o-body--menu-on .o-header__middle {
  transform: none;
  opacity: 1;
  transition: opacity 0.15s, transform 0s; }

.o-body--home .o-header {
  background: transparent;
  border-bottom: 1px solid rgba(216, 241, 252, 0.2);
  z-index: 2; }
  @media print, screen and (min-width: 64em) {
    .o-body--home .o-header {
      border-bottom: none; } }
  .o-body--home .o-header__svg {
    display: none; }
    @media print, screen and (min-width: 64em) {
      .o-body--home .o-header__svg {
        display: block; } }
    .o-body--home .o-header__svg--white {
      display: block; }
      @media print, screen and (min-width: 64em) {
        .o-body--home .o-header__svg--white {
          display: none; } }

@media print, screen and (min-width: 64em) {
  .o-body--static .o-header {
    position: absolute;
    z-index: 2;
    transform: translateY(0%) !important; } }

@media print, screen and (min-width: 64em) {
  .o-body--static .o-header:before {
    border-color: transparent; } }

@media print, screen and (min-width: 64em) {
  .o-body--static .o-header__banner {
    top: 42px; } }

@media print, screen and (min-width: 64em) {
  .o-body--static .o-header__container:before {
    border-color: transparent; } }

@media print, screen and (min-width: 64em) {
  .o-body--home.o-body--static .o-header {
    background: transparent;
    border-bottom: 1px solid rgba(216, 241, 252, 0.2);
    z-index: 2; } }

@media print, screen and (min-width: 64em) {
  .o-body--home.o-body--static .o-header:before {
    background: transparent;
    border-color: transparent; } }

@media print, screen and (min-width: 64em) {
  .o-body--home.o-body--static .o-header__container:before {
    background: transparent;
    border-color: transparent; } }

@media screen and (min-width: 80em) and (min-width: 64em) {
  .o-body--home.o-body--static .o-header__container:after {
    background: rgba(216, 241, 252, 0.2); } }

@media print, screen and (min-width: 64em) {
  .o-body--home.o-body--static .o-header__svg {
    display: none; } }

@media print, screen and (min-width: 64em) {
  .o-body--home.o-body--static .o-header__svg--white {
    display: block; } }

.o-block {
  margin-top: 80px;
  margin-bottom: 80px; }
  @media print, screen and (min-width: 64em) {
    .o-block {
      margin-top: 120px;
      margin-bottom: 120px; } }
  .o-block--social-network {
    text-align: center; }
  .o-block--padded {
    padding-top: 80px;
    padding-bottom: 80px;
    margin: 0; }
    @media screen and (min-width: 80em) {
      .o-block--padded .o-block__container:after {
        top: -80px;
        bottom: -80px; } }
  .o-block--with-margin {
    margin-top: 80px;
    margin-bottom: 80px; }
    @media print, screen and (min-width: 64em) {
      .o-block--with-margin {
        margin-top: 120px;
        margin-bottom: 120px; } }
    @media screen and (min-width: 80em) {
      .o-block--with-margin .o-block__container:after {
        content: "";
        position: absolute;
        top: -200px;
        right: 20px;
        bottom: -120px;
        z-index: 1;
        width: 1px;
        background-color: #7fbad4; } }
  .o-block--primary {
    background-color: #2a6485; }
  .o-block--secondary {
    background-color: #fd7373; }
  .o-block--tertiary {
    background-color: #7fbad4; }
  .o-block--primary-dark {
    background-image: linear-gradient(to right, #2a6485, #7fbad4 125%); }
    @media screen and (min-width: 80em) {
      .o-block--primary-dark {
        background-image: linear-gradient(to right, #2a6485 49.99%, #fff 50%); } }
    @media screen and (min-width: 80em) {
      .o-block--primary-dark .o-block__container:before {
        content: "";
        position: absolute;
        top: -80px;
        right: 20px;
        bottom: -80px;
        left: 20px;
        background-image: linear-gradient(to right, #2a6485, #7fbad4 125%); } }
  .o-block--grey {
    background: #f3f8fb; }
    @media screen and (min-width: 80em) {
      .o-block--grey {
        background-image: linear-gradient(to right, #f3f8fb 49.99%, #fff 50%); } }
    @media screen and (min-width: 80em) {
      .o-block--grey .o-block__container:before {
        content: "";
        position: absolute;
        top: -80px;
        right: 20px;
        bottom: -80px;
        left: 20px;
        background: #f3f8fb; } }
  .o-block--grey-dark {
    background: #d8f1fc; }
    @media screen and (min-width: 80em) {
      .o-block--grey-dark {
        background-image: linear-gradient(to right, #d8f1fc 49.99%, #fff 50%); } }
    @media screen and (min-width: 80em) {
      .o-block--grey-dark .o-block__container:before {
        content: "";
        position: absolute;
        top: -80px;
        right: 20px;
        bottom: -80px;
        left: 20px;
        background: #d8f1fc; } }
  @media screen and (min-width: 80em) {
    .o-block--no-left {
      background: transparent; } }
  .o-block--overflow-hidden {
    overflow: hidden; }
  .o-block--no-top {
    margin-top: 0; }
  .o-block--no-bot {
    margin-bottom: 0; }
  .o-block--top {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 170px;
    padding-bottom: 80px;
    background-image: linear-gradient(to right, #2a6485, #7fbad4 200%); }
    @media print, screen and (min-width: 64em) {
      .o-block--top {
        padding-bottom: 100px;
        background-image: linear-gradient(to right, #2a6485, #7fbad4); } }
    @media screen and (min-width: 80em) {
      .o-block--top .o-block__container:after {
        display: none; } }
    .o-block--top .a-btn {
      background: #fd7373;
      color: #fff;
      border-color: #fd7373; }
      .o-block--top .a-btn:before, .o-block--top .a-btn:after {
        border-color: #fff; }
  .o-block--button {
    text-align: center; }
  .o-block--button-home {
    text-align: left; }
    @media screen and (min-width: 80em) {
      .o-block--button-home {
        background: linear-gradient(to right, #086587 49.99%, #fff 50%); } }
    @media screen and (min-width: 80em) {
      .o-block--button-home .o-block__container:before {
        background-image: url(../img/formations.jpg);
        background-size: cover;
        background-position: center; } }
    @media print, screen and (min-width: 48em) {
      .o-block--button-home .o-block__content:first-child {
        flex: 0 0 auto;
        width: calc(66.66667% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        margin-left: calc(0% + 2.5rem / 2); } }
    @media print, screen and (min-width: 64em) {
      .o-block--button-home .o-block__content:first-child {
        flex: 0 0 auto;
        width: calc(50% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        margin-left: calc(0% + 2.5rem / 2); } }
  @media print, screen and (min-width: 64em) {
    .o-block--even .o-block__left, .o-block--text-image-left .o-block__left {
      order: 2; } }
  @media print, screen and (min-width: 64em) {
    .o-block--even .o-block__right, .o-block--text-image-left .o-block__right {
      flex: 0 0 auto;
      width: calc(41.66667% - 2.5rem);
      margin-left: 1.25rem;
      margin-right: 1.25rem; } }
  .o-block--newsletter {
    background: #fd7373;
    margin-bottom: 0; }
    @media screen and (min-width: 80em) {
      .o-block--newsletter {
        background-image: linear-gradient(to right, #fd7373 49.99%, #fff 50%);
        position: relative; } }
    @media screen and (min-width: 80em) {
      .o-block--newsletter:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        bottom: 0;
        left: 50%;
        background: #2a6485; } }
    @media screen and (min-width: 80em) {
      .o-block--newsletter .o-block__container {
        z-index: 1; } }
    @media screen and (min-width: 80em) {
      .o-block--newsletter .o-block__container:before {
        content: "";
        position: absolute;
        top: 0;
        right: 20px;
        bottom: 0;
        left: 20px;
        background: #fd7373; } }
    .o-block--newsletter .o-block__container:after {
      bottom: 50%; }
  .o-block--expertises {
    margin-bottom: 0; }
    @media screen and (min-width: 80em) {
      .o-block--expertises {
        margin-top: -80px;
        padding-top: 40px;
        padding-bottom: 40px;
        background-image: linear-gradient(to right, #fff 49.99%, rgba(255, 255, 255, 0) 50%);
        position: relative;
        z-index: 1; } }
    @media screen and (min-width: 80em) {
      .o-block--expertises:after {
        content: "";
        position: absolute;
        top: 80px;
        right: 0;
        bottom: 0;
        left: 50%;
        background: #f3f8fb; } }
    .o-block--expertises .o-block__container {
      position: relative;
      z-index: 1; }
      @media screen and (min-width: 80em) {
        .o-block--expertises .o-block__container:before {
          content: "";
          position: absolute;
          top: -40px;
          right: 20px;
          bottom: -40px;
          left: 20px;
          background: #fff; } }
      @media screen and (min-width: 80em) {
        .o-block--expertises .o-block__container:after {
          top: -40px; } }
  .o-block--event {
    position: relative; }
    @media print, screen and (min-width: 64em) {
      .o-block--event {
        padding-top: 40px;
        padding-bottom: 40px;
        background-image: linear-gradient(to right, #fd7373 24.99%, rgba(253, 115, 115, 0) 25%); } }
    @media screen and (min-width: 80em) {
      .o-block--event:before {
        content: "";
        position: absolute;
        top: -120px;
        right: 0;
        bottom: 50%;
        left: 0;
        z-index: -1;
        background: #f3f8fb; } }
  .o-block--list {
    position: relative;
    /* &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 100px;
      background-image: linear-gradient(to right, $primary, $tertiary);
    } */ }
    .o-block--list .o-block__container:after {
      display: none; }
  .o-block--cta {
    margin-bottom: 0px;
    text-align: center; }
    .o-block--cta .o-block__container {
      align-items: stretch; }
      .o-block--cta .o-block__container:after {
        bottom: 0; }
    .o-block--cta .o-block__left {
      margin-bottom: 0; }
  .o-block__container {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    width: 100%; }
    @media screen and (min-width: 80em) {
      .o-block__container {
        position: relative; } }
    @media screen and (min-width: 80em) {
      .o-block__container:after {
        content: "";
        position: absolute;
        top: -120px;
        right: 20px;
        bottom: -120px;
        z-index: 1;
        width: 1px;
        background-color: #7fbad4; } }
    @media print, screen and (min-width: 64em) {
      .o-block__container--stretch {
        align-items: stretch; } }
  .o-block--testimonials .o-block__container::before {
    background: linear-gradient(to top, #f3f8fb 72%, #fff 50%);
    left: -9000px;
    overflow: hidden; }
  .o-block--cases {
    background: #f3f8fb;
    overflow: hidden; }
    @media print, screen and (min-width: 64em) {
      .o-block--cases {
        margin-top: 0; } }
    @media screen and (min-width: 80em) {
      .o-block--cases {
        padding-top: 0;
        background: transparent; } }
    @media screen and (min-width: 80em) {
      .o-block--cases .o-block__container:before {
        content: '';
        position: absolute;
        top: 160px;
        right: 20px;
        bottom: -80px;
        left: -9999px;
        background: #f3f8fb; } }
    @media screen and (min-width: 80em) {
      .o-block--cases .o-block__container:after {
        top: -60px; } }
  .o-block__content {
    flex: 0 0 auto;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-bottom: 40px; }
    .o-block__content:last-child {
      margin-bottom: 0; }
    @media print, screen and (min-width: 64em) {
      .o-block__content {
        margin-bottom: 40px; } }
    @media screen and (min-width: 80em) {
      .o-block__content {
        flex: 0 0 auto;
        width: calc(83.33333% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        margin-left: calc(8.33333% + 2.5rem / 2);
        position: relative;
        z-index: 1; } }
    @media print, screen and (min-width: 64em) {
      .o-block__content--slim {
        flex: 0 0 auto;
        width: calc(83.33333% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        margin-left: calc(8.33333% + 2.5rem / 2); } }
    @media screen and (min-width: 80em) {
      .o-block__content--slim {
        flex: 0 0 auto;
        width: calc(66.66667% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        margin-left: calc(16.66667% + 2.5rem / 2); } }
    @media print, screen and (min-width: 48em) {
      .o-block__content--slimer {
        flex: 0 0 auto;
        width: calc(66.66667% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        margin-left: calc(16.66667% + 2.5rem / 2); } }
    @media print, screen and (min-width: 64em) {
      .o-block__content--slimer {
        flex: 0 0 auto;
        width: calc(50% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        margin-left: calc(25% + 2.5rem / 2); } }
    @media screen and (min-width: 80em) {
      .o-block__content--full {
        flex: 0 0 auto;
        width: calc(100% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem; } }
    @media print, screen and (min-width: 64em) {
      .o-block__content--left {
        flex: 0 0 auto;
        width: calc(83.33333% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem; } }
    @media screen and (min-width: 80em) {
      .o-block__content--left {
        flex: 0 0 auto;
        width: calc(66.66667% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem; } }
    .o-block__content--centered {
      text-align: center; }
  .o-block__left {
    flex: 0 0 auto;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-bottom: 40px; }
    @media print, screen and (min-width: 64em) {
      .o-block__left {
        flex: 0 0 auto;
        width: calc(50% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        margin-bottom: 0; } }
    .o-block__left--grey-dark {
      background-color: #d8f1fc; }
    .o-block__left--padded {
      padding: 40px;
      box-sizing: border-box; }
  .o-block__right {
    flex: 0 0 auto;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
    @media print, screen and (min-width: 64em) {
      .o-block__right {
        flex: 0 0 auto;
        width: calc(50% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem; } }
    .o-block__right--grey {
      background-color: #f3f8fb; }
    .o-block__right--padded {
      padding: 40px;
      box-sizing: border-box; }
    @media print, screen and (min-width: 64em) {
      .o-block__right--lift {
        position: relative; } }
  @media print, screen and (min-width: 64em) {
    .o-block__lift {
      position: sticky;
      top: 90px; } }
  .o-block__image {
    position: absolute;
    top: 0;
    left: 50%;
    border-radius: 50%;
    overflow: hidden;
    width: 60px;
    transform: translate(-50%, -50%); }
    .o-block__image img {
      display: block; }
  .o-block__commercial {
    margin-top: 30px; }
    .o-block__commercial picture {
      display: inline-block;
      vertical-align: middle;
      margin-right: 20px; }
    .o-block__commercial img {
      border-radius: 50%;
      width: 5em; }
    .o-block__commercial .a-btn {
      vertical-align: middle; }

.o-home {
  margin-top: -70px;
  background: #2a6485;
  position: relative;
  box-sizing: border-box; }
  @media print, screen and (min-width: 64em) {
    .o-home {
      margin-top: 0; } }
  .o-home:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-image: linear-gradient(to right, rgba(42, 100, 133, 0.9), rgba(42, 100, 133, 0.6)); }
    @media print, screen and (min-width: 64em) {
      .o-home:before {
        background-image: linear-gradient(to right, #2a6485 25%, rgba(42, 100, 133, 0.5)); } }
  .o-home__container {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    min-height: 75vh; }
    @media screen and (min-width: 80em) {
      .o-home__container {
        position: relative;
        padding-bottom: 80px;
        box-sizing: border-box; } }
    @media screen and (min-width: 85.375em) {
      .o-home__container {
        min-height: 80vh; } }
    @media screen and (min-width: 80em) {
      .o-home__container:after {
        content: '';
        position: absolute;
        top: 70px;
        right: 20px;
        bottom: 0;
        width: 1px;
        background: rgba(216, 241, 252, 0.2); } }
  .o-home__content {
    flex: 0 0 auto;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    padding-top: 150px;
    padding-bottom: 80px;
    position: relative;
    z-index: 1; }
    @media print, screen and (min-width: 64em) {
      .o-home__content {
        flex: 0 0 auto;
        width: calc(66.66667% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem; } }
  .o-home img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    object-fit: cover; }
  .o-home__select {
    display: inline-block;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    cursor: pointer; }
    @media print, screen and (min-width: 64em) {
      .o-home__select {
        margin-top: 40px; } }
    .o-home__select:before {
      content: '';
      position: absolute;
      right: 18px;
      bottom: 0;
      left: 0;
      height: 1px;
      background: #fff; }
    .o-home__select:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 2px;
      width: 6px;
      height: 6px;
      background: transparent;
      border-right: 2px solid #7fbad4;
      border-bottom: 2px solid #7fbad4;
      transform: translateY(-75%) rotate(45deg);
      pointer-events: none; }
    .o-home__select select {
      appearance: none;
      outline: 0;
      box-shadow: none;
      border: 0 !important;
      background-image: none;
      background: transparent;
      font-size: 16px;
      font-size: 1rem;
      font-family: "Open Sans", sans-serif;
      color: #9bb1c8;
      height: 36px;
      padding-right: 20px;
      cursor: pointer; }
      @media print, screen and (min-width: 64em) {
        .o-home__select select {
          font-size: 18px;
          font-size: 1.125rem; } }
      .o-home__select select::-ms-expand {
        display: none; }
      .o-home__select select option {
        background: #fff;
        color: #2a6485;
        font-size: 14px;
        font-size: 0.875rem; }

@media print, screen and (min-width: 64em) {
  .o-page {
    position: relative;
    box-sizing: border-box;
    padding-top: 160px;
    margin-bottom: 80px; } }

@media screen and (min-width: 80em) {
  .o-page:before {
    content: '';
    background-image: linear-gradient(to right, rgba(42, 100, 133, 0) 50%, #2a6485 50.05%);
    position: absolute;
    top: 160px;
    right: 0;
    bottom: 0;
    left: 0; } }

.o-page__container {
  display: flex;
  flex-flow: row wrap;
  margin-top: -40px; }
  @media print, screen and (min-width: 48em) {
    .o-page__container {
      margin-top: -180px;
      position: relative;
      z-index: 1; } }
  @media print, screen and (min-width: 64em) {
    .o-page__container {
      margin-top: 0; } }
  @media print, screen and (min-width: 48em) {
    .o-page__container:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 75%;
      z-index: -1;
      background: #2a6485; } }
  @media screen and (min-width: 80em) {
    .o-page__container:after {
      content: '';
      position: absolute;
      right: 20px;
      bottom: -80px;
      z-index: 1;
      width: 1px;
      height: 80px;
      background-color: #7fbad4; } }

.o-page__content {
  flex: 0 0 auto;
  width: calc(100% - 2.5rem);
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  background: #2a6485;
  padding: 40px 20px;
  box-sizing: border-box;
  position: relative;
  z-index: 1; }
  @media print, screen and (min-width: 48em) {
    .o-page__content {
      flex: 0 0 auto;
      width: calc(66.66667% - 2.5rem);
      margin-left: 1.25rem;
      margin-right: 1.25rem;
      margin-left: calc(33.33333% + 2.5rem / 2); } }
  @media print, screen and (min-width: 64em) {
    .o-page__content {
      flex: 0 0 auto;
      width: calc(58.33333% - 2.5rem);
      margin-left: 1.25rem;
      margin-right: 1.25rem;
      margin-left: calc(41.66667% + 2.5rem / 2);
      padding: 40px; } }
  .o-page__content .a-text {
    max-width: 400px; }
  .o-page__content .a-btn {
    background: #fd7373;
    color: #fff;
    border-color: #fd7373;
    margin-top: 20px; }
    @media print, screen and (min-width: 64em) {
      .o-page__content .a-btn {
        margin-top: 30px; } }
    .o-page__content .a-btn:before, .o-page__content .a-btn:after {
      border-color: #fff; }
  .o-page__content--large .a-text {
    max-width: none;
    width: auto; }

.o-page img {
  display: block;
  margin: auto; }
  @media print, screen and (min-width: 64em) {
    .o-page img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: calc(100% - 60px);
      width: 100%;
      object-fit: cover;
      margin-top: -30px; } }
  @media screen and (min-width: 80em) {
    .o-page img {
      z-index: -1; } }

.o-footer {
  background-color: #2a6485;
  padding-top: 60px;
  padding-bottom: 60px; }
  .o-footer__container {
    display: flex;
    flex-flow: row wrap;
    width: 100%; }
  .o-footer__menu {
    flex: 0 0 auto;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-bottom: 40px; }
    @media print, screen and (min-width: 48em) {
      .o-footer__menu {
        flex: 0 0 auto;
        width: calc(75% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem; } }
    @media print, screen and (min-width: 64em) {
      .o-footer__menu {
        flex: 0 0 auto;
        width: calc(66.66667% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        margin-bottom: 0; } }
  .o-footer__elee {
    flex: 0 0 auto;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-bottom: 40px; }
    @media print, screen and (min-width: 64em) {
      .o-footer__elee {
        flex: 0 0 auto;
        width: calc(25% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        margin-left: calc(8.33333% + 2.5rem / 2);
        margin-bottom: 0; } }
    @media screen and (min-width: 80em) {
      .o-footer__elee {
        flex: 0 0 auto;
        width: calc(16.66667% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        margin-left: calc(16.66667% + 2.5rem / 2); } }
    .o-footer__elee .a-text {
      margin-top: 20px;
      font-size: 14px;
      font-size: 0.875rem; }
  .o-footer__bottom {
    flex: 0 0 auto;
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .o-footer__logo img {
    display: block;
    width: 99px;
    margin-bottom: 20px; }
